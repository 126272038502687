import React, {Component} from 'react';

export default class BoxImagePoint extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            canvasV:undefined,
            ctxV:undefined,
        }
     
        this.clickCanvas = this.clickCanvas.bind(this);
        this.getPosition = this.getPosition.bind(this);
        this.drawCoordinates = this.drawCoordinates.bind(this);
    } 

    clickCanvas(e){
        this.getPosition(e);
    }

    getPosition(event){
        let rect = this.state.canvasV.getBoundingClientRect();
        let x = event.clientX - rect.left;
        let y = event.clientY - rect.top;
           
        this.drawCoordinates(x,y);
   }

   
    drawCoordinates(x,y){	
        let ctx = this.state.ctxV;
        ctx.fillStyle = "rgb(32,168,216)"; // Red color

        let rect = this.state.canvasV.getBoundingClientRect();
        ctx.clearRect(0, 0, rect.width, rect.height);
        ctx.drawImage(this.refs.image, rect.width/2-this.refs.image.width/2, rect.height/2-this.refs.image.height/2);
        ctx.beginPath();
        ctx.arc(x, y, 3, 0, Math.PI * 2, true);
        ctx.fill();
        this.setState({ctxV:ctx},()=>{
            let image = this.state.canvasV.toDataURL("image/png").replace("image/png", "image/octet-stream"); 
            //window.location.href=image; // it will save locally
            if (this.props.Save){
                this.props.Save(image);
            }
        });
    }

    
    componentDidMount() {
        let canvasV = this.refs.canvas
        let ctxV = canvasV.getContext("2d");

        this.setState({canvasV:canvasV,ctxV:ctxV});
    }

    render()
    {
        return (                   
            <canvas 
                onClick={this.clickCanvas} 
                ref="canvas" 
                width="100%" 
                height="100%"
                style={{
                    cursor:"crosshair",
                    backgroundColor:'white',
                    backgroundImage:"url("+this.props.ImagemDefault+")",
                    //backgroundImage:"url(http://localhost:3000/analise/acometida/imagem/47287552/2/sim/3)",
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center' 
                }}
            >
                     {/* retorno = 'http://localhost:3000/analise/acometida/imagem/47287552/2/sim/3' */}
                    <img ref="image" src={this.props.Imagem} alt="nenhuma imagem" />
            </canvas>           
        )
    }
};