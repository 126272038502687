import React, { Component } from 'react';
import ButtonCircle from '../ButtonCircle/ButtonCircle';
import CardBodyBark from '../Base/CardBodyBark/CardBodyBark';
import CardBark from '../Base/CardBark/CardBark';
import CardFooterBark from '../Base/CardFooterBark/CardFooterBark';
import ColBark from '../Base/ColBark/ColBark';
import RowBark from '../Base/RowBark/RowBark';
import { ContainerCard } from './styles';

const imagens_salt = [
    { imagem: '../../assets/img/escalas/salt_atras.png', buttons: [{ label: '6%', value: 6 }, { label: '6%', value: 6 }, { label: '6%', value: 6 }, { label: '6%', value: 6 }] },
    { imagem: '../../assets/img/escalas/salt_topo.png', buttons: [{ label: '10%', value: 10 }, { label: '10%', value: 10 }, { label: '10%', value: 10 }, { label: '10%', value: 10 }] },
    { imagem: '../../assets/img/escalas/salt_esquerdo.png', buttons: [{ label: '4%', value: 4 }, { label: '5%', value: 5 }, { label: '5%', value: 5 }, { label: '4%', value: 4 }] },
    { imagem: '../../assets/img/escalas/salt_direito.png', buttons: [{ label: '5%', value: 5 }, { label: '4%', value: 4 }, { label: '4%', value: 4 }, { label: '5%', value: 5 }] },
];

const imagens_olsen = [
    { imagem: '../../assets/img/escalas/olsen_1.png', buttons: [{ label: 'Ilustração 1', value: 75 }] },
    { imagem: '../../assets/img/escalas/olsen_2.png', buttons: [{ label: 'Ilustração 2', value: 50 }] },
    { imagem: '../../assets/img/escalas/olsen_3.png', buttons: [{ label: 'Ilustração 3', value: 25 }] },
    { imagem: '../../assets/img/escalas/olsen_4.png', buttons: [{ label: 'Não se Aplica', value: 100 }] },
];

const imagens_ludwig = [
    { imagem: '../../assets/img/escalas/ludwig_L1.png', buttons: [{ label: 'I-1', value: 100 }] },
    { imagem: '../../assets/img/escalas/ludwig_L2.png', buttons: [{ label: 'I-2', value: 90 }] },
    { imagem: '../../assets/img/escalas/ludwig_L3.png', buttons: [{ label: 'I-3', value: 80 }] },
    { imagem: '../../assets/img/escalas/ludwig_L4.png', buttons: [{ label: 'I-4', value: 80 }] },
    { imagem: '../../assets/img/escalas/ludwig_LL1.png', buttons: [{ label: 'II-1', value: 60 }] },
    { imagem: '../../assets/img/escalas/ludwig_LL2.png', buttons: [{ label: 'II-2', value: 60 }] },
    { imagem: '../../assets/img/escalas/ludwig_LLL.png', buttons: [{ label: 'III', value: 40 }] },
    { imagem: '../../assets/img/escalas/ludwig_frontal.png', buttons: [{ label: 'Frontal', value: 40 }] },
    { imagem: '../../assets/img/escalas/ludwig_avancado.png', buttons: [{ label: 'Avançado', value: 20 }] },
];

const imagens_norwood = [
    { imagem: '../../assets/img/escalas/norwood_i.png', buttons: [{ label: 'I', value: 100 }] },
    { imagem: '../../assets/img/escalas/norwood_ii.png', buttons: [{ label: 'II', value: 80 }] },
    { imagem: '../../assets/img/escalas/norwood_iia.png', buttons: [{ label: 'IIa', value: 80 }] },
    { imagem: '../../assets/img/escalas/norwood_iiia.png', buttons: [{ label: 'IIIa', value: 60 }] },
    { imagem: '../../assets/img/escalas/norwood_iii.png', buttons: [{ label: 'III', value: 60 }] },
    { imagem: '../../assets/img/escalas/norwood_iii_vetex.png', buttons: [{ label: 'III Vetex', value: 60 }] },
    { imagem: '../../assets/img/escalas/norwood_iv.png', buttons: [{ label: 'IV', value: 40 }] },
    { imagem: '../../assets/img/escalas/norwood_iva.png', buttons: [{ label: 'IVa', value: 40 }] },
    { imagem: '../../assets/img/escalas/norwood_v.png', buttons: [{ label: 'V', value: 40 }] },
    { imagem: '../../assets/img/escalas/norwood_va.png', buttons: [{ label: 'Va', value: 20 }] },
    { imagem: '../../assets/img/escalas/norwood_vi.png', buttons: [{ label: 'VI', value: 20 }] },
    { imagem: '../../assets/img/escalas/norwood_vii.png', buttons: [{ label: 'VII', value: 20 }] },
];


export default class QuadroEscala extends Component {
    constructor(props) {
        super(props);

        this.state = {
            salt_selecionado: [false, false, false, false,
                false, false, false, false,
                false, false, false, false,
                false, false, false, false],
            altura_quadros: 15,
        };

        this.getLinhas = this.getLinhas.bind(this);
        this.getCardImage = this.getCardImage.bind(this);
        this.getImagem = this.getImagem.bind(this);
        this.getTamanhoCardEscala = this.getTamanhoCardEscala.bind(this);
        this.getSelecionado = this.getSelecionado.bind(this);
        this.clicado = this.clicado.bind(this);
        this.getButtons = this.getButtons.bind(this);
        this.getItemButton = this.getItemButton.bind(this);
    }

    clicado(idx, selecionado) {
        if (this.props.modelo.toString() === 'salt') {
            let idx_orig = parseInt(selecionado.toString()[selecionado.toString().length - 1]);
            let idx_bloco = idx_orig * 4;
            let idx_index = parseInt(selecionado.toString()[0]) - 1;
            let sel = this.state.salt_selecionado;
            sel[idx_bloco + idx_index] = !sel[idx_bloco + idx_index];
            let v = 0;
            let i = 0;
            while (i !== sel.length) {
                if (sel[i]) {
                    let b = (i / 4).toString().substring((i / 4).toString().indexOf('.') + 1).toString();
                    b = b === '25' ? 1 : b === '5' ? 2 : b === '75' ? 3 : 0;
                    v += imagens_salt[Math.trunc(i / 4)].buttons[b].value;
                }
                i++;
            }
            sel = sel.toString().split("false").join("0").split("true").join("1");
            this.props.onClick(sel, v);
        } else {
            this.props.onClick(idx, this.getItemEscala(idx).buttons[0].value);
        }
    }

    getTamanhoCardEscala() {
        let retorno = 3;
        if (this.props.modelo.toString() === 'ludwig') {
            retorno = 2;
        } else if (this.props.modelo.toString() === 'norwood') {
            retorno = 2;
        } else if (this.props.modelo.toString() === 'salt') {
            retorno = 6;
        }
        return retorno;
    }

    getItemEscala(idx) {
        let retorno = '';
        if (this.props.modelo.toString() === 'olsen') {
            retorno = imagens_olsen[idx];
        } else if (this.props.modelo.toString() === 'salt') {
            retorno = imagens_salt[idx];
        } else if (this.props.modelo.toString() === 'ludwig') {
            retorno = imagens_ludwig[idx];
        } else if (this.props.modelo.toString() === 'norwood') {
            retorno = imagens_norwood[idx];
        }
        return retorno;
    }

    getImagem(idx) {
        let retorno = [];
        retorno.push(
            <ContainerCard key={retorno.length}>
                <img alt="nenhuma foto" src={this.getItemEscala(idx).imagem} />
            </ContainerCard>
        );
        return retorno;
    }

    getSelecionado(idx, selecionado) {
        let retorno = '';
        if (this.props.modelo.toString() === 'salt') {
            let sel = this.props.selecao.toString();
            sel = sel.toString().split("0").join("false").split("1").join("true").split(",");
            if (sel.length === 16) {
                let y = 0;
                while (y !== sel.length) {
                    sel[y] = sel[y].toString() === 'true';
                    y++;
                }
                let idx_orig = parseInt(selecionado.toString()[selecionado.toString().length - 1]);
                let idx_bloco = idx_orig * 4;
                let idx_index = parseInt(selecionado.toString()[0]) - 1;
                retorno = sel[idx_bloco + idx_index] ? 'primary' : 'outline-primary';
                if ((Array.isArray(sel)) &&
                    (sel.length === 16) &&
                    (this.state.salt_selecionado.toString().split("false").join("0").split("true").join("1").toString() !== sel.toString().split("false").join("0").split("true").join("1").toString())) {
                    this.setState({ salt_selecionado: sel });
                }
            } else {
                retorno = 'outline-primary';
            }

        } else {
            retorno = idx.toString() === this.props.selecao.toString() ? 'primary' : 'outline-primary';
        }
        return retorno;
    }

    getItemButton(idx, posicao, sel) {
        let retorno = [];
        retorno.push(
            <ButtonCircle pad={false} style={{ height: '90%', fontSize: "0.8em", marginTop: '3%', padding: '0% 2% 2% 2%' }}
                onClick={(e) => this.clicado(idx, sel)}
                block size="sm" color={this.getSelecionado(idx, sel)}>{this.getItemEscala(idx).buttons[posicao].label}</ButtonCircle>
        )
        return retorno;
    }

    getButtons(idx) {
        let retorno = [];
        let total = this.getItemEscala(idx).buttons.length;
        if (total === 1) {
            retorno.push(this.getItemButton(idx, 0));
        } else {
            retorno.push(
                <RowBark>
                    <ColBark sm={12} md={12} xs={12} style={{ padding: '0% 1px 0% 15px' }}>{this.getItemButton(idx, 0, idx + 1000)}</ColBark>
                    <ColBark sm={12} md={12} xs={12} style={{ padding: '0% 15px 0% 1px' }}>{this.getItemButton(idx, 1, idx + 2000)}</ColBark>
                </RowBark>
            );
            retorno.push(
                <RowBark>
                    <ColBark sm={12} md={12} xs={12} style={{ padding: '0% 1px 0% 15px' }}>{this.getItemButton(idx, 2, idx + 3000)}</ColBark>
                    <ColBark sm={12} md={12} xs={12} style={{ padding: '0% 15px 0% 1px' }}>{this.getItemButton(idx, 3, idx + 4000)}</ColBark>
                </RowBark>
            );
        }
        return retorno;
    }

    getCardImage(idx) {
        let retorno = [];
        let tamanhoCard = this.getTamanhoCardEscala();

        retorno.push(
            <ColBark key={idx} xs={12} style={{ padding: '0%' }} sm={parseInt(tamanhoCard) * 2} md={parseInt(tamanhoCard) * 2}>
                <CardBark style={{ marginBottom: '0%', border: 'none' }}>
                    <CardBodyBark style={{ height: "15vh", padding: '0% 0% 0% 0%' }}>
                        {this.getImagem(idx)}
                    </CardBodyBark>
                    <CardFooterBark key={retorno.length} style={{ height: "auto", border: 'none', background: '#fff', fontSize: "0.9em", padding: '0% 2% 2% 2%' }}>
                        {this.getButtons(idx)}
                    </CardFooterBark>
                </CardBark>
            </ColBark>
        );

        return retorno;
    }

    getLinhas() {
        let retorno = [];
        if (this.props.modelo.toString() === 'ludwig') {
            retorno.push(
                <RowBark key={retorno.length}>
                    {this.getCardImage(0)}
                    {this.getCardImage(1)}
                    {this.getCardImage(2)}
                    {this.getCardImage(3)}
                    {this.getCardImage(4)}
                    {this.getCardImage(5)}
                </RowBark>
            );
            retorno.push(
                <RowBark key={retorno.length}>
                    {this.getCardImage(6)}
                    {this.getCardImage(7)}
                    {this.getCardImage(8)}
                </RowBark>
            );
        } else if (this.props.modelo.toString() === 'norwood') {
            retorno.push(
                <RowBark key={retorno.length}>
                    {this.getCardImage(0)}
                    {this.getCardImage(1)}
                    {this.getCardImage(2)}
                    {this.getCardImage(3)}
                    {this.getCardImage(4)}
                    {this.getCardImage(5)}
                </RowBark>
            );
            retorno.push(
                <RowBark key={retorno.length}>
                    {this.getCardImage(6)}
                    {this.getCardImage(7)}
                    {this.getCardImage(8)}
                    {this.getCardImage(9)}
                    {this.getCardImage(10)}
                    {this.getCardImage(11)}
                </RowBark>
            );
        } else if (this.props.modelo.toString() === 'salt') {
            retorno.push(
                <RowBark key={retorno.length}>
                    {this.getCardImage(0)}
                    {this.getCardImage(1)}
                </RowBark>
            );
            retorno.push(
                <RowBark key={retorno.length}>
                    {this.getCardImage(2)}
                    {this.getCardImage(3)}
                </RowBark>
            );
        } else {
            retorno.push(
                <RowBark key={retorno.length}>
                    {this.getCardImage(0)}
                    {this.getCardImage(1)}
                    {this.getCardImage(2)}
                    {this.getCardImage(3)}
                </RowBark>
            );
        }
        return retorno;
    }

    render() {
        return (
            <ColBark xs={24} sm={24} md={24}>
                {this.getLinhas()}
            </ColBark>
        )
    }
};