import styled from "styled-components";
import ColBark from "../Base/ColBark/ColBark";
import RowBark from "../Base/RowBark/RowBark";

export const BoxButtons = styled.div`
  display: flex;
`

export const ColStyled  = styled(ColBark)`
  display: flex;
`

export const RowStyled  = styled(RowBark)`
  margin-top: 20px;
  width: 98%;  
`

export const Box = styled.div` 
  min-height: 100%;
  height: ${props => props.altura ? props.altura : props.window ?  "32vh" : "27vh"};
  padding: 0% 0% 0% 0%;
  border:2px solid #F5F5F5;
  border-radius: 20px;
`

export const BoxCenterImage = styled.div`
  text-align:left;
  height:calc(100% - 30px);
  display:flex; 
  align-items:center;
  justify-content:center;
  background-image:${p => `url(${p.image})`};
  background-repeat: no-repeat;
  background-size: 300px 300px;  
  background-position: center;

  > div {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center; */
  }

  :hover > div {
    opacity: 1;
  }

  :hover > img {
    opacity: 0.1;
  }

  :hover {
    background-image:${p => `linear-gradient(to bottom,rgba(255,250,250, 0.5),rgba(255,250,250, 0.5)), url(${p.image})`}
  }
`

export const BoxTitle = styled.p`
  position: inherit;
  background-color: ${props => props.primary};
  color:white;
  margin: 0px;
  border-radius: 20px 20px 0px 0px;
  height: 30px;
  right: 0;
  left: 0;
  text-align: center;
  padding-top: 4px;
`

export const ImageStyled = styled.img`
  opacity: 1;
  height: 100%;
  transition: .5s ease;
  backface-visibility: hidden;

  //width: 100%;
  //border-radius: 0px 0px 20px 20px;
`
