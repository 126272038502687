import React, {Component} from 'react';
import ButtonCircle from '../../ButtonCircle/ButtonCircle';
import { ModalBark, ModalBodyBark, ModalFooterBark, ModalHeaderBark } from '../../Base/ModalBark/ModalBark';

export default class ButtonModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: props.initialModalState,
            fade: true,
            inButton:props.inButton,
            onYes: props.onYes,
        };

        this.toggle = this.toggle.bind(this);
        this.toYes = this.toYes.bind(this);
    }

    toYes() {
        this.setState({
            modal: !this.state.modal,
            fade: !this.state.fade
        });
        this.state.onYes(this.props.chave);
    }

    toggle(e) {
        console.log(e);
        this.setState({
            modal: !this.state.modal,
            fade: !this.state.fade

        });
    }

    render() {
        return (
                <ButtonCircle color={this.props.color}  size="sm" onClick={this.toggle}>{this.props.inButton}
                    <ModalBark isOpen={this.state.modal} toggle={this.toggle}
                        fade={this.state.fade}>
                        <ModalHeaderBark toggle={this.toggle}>{"Exclusão"}</ModalHeaderBark>
                        <ModalBodyBark>{this.props.texto}</ModalBodyBark>
                        <ModalFooterBark>                  
                            <ButtonCircle color="secondary" onClick={this.toYes}>Sim</ButtonCircle>
                            <ButtonCircle color="primary" onClick={this.toggle}>Não</ButtonCircle>
                        </ModalFooterBark>
                    </ModalBark>
                </ButtonCircle>
        );
    }
}