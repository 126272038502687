import React, { Component } from 'react';
import Ferramentas from '../../classes/ferramentas';
import ColorContext from '../../ColorContext';
import ButtonCircle from '../ButtonCircle/ButtonCircle';
import { BoxIconSearchCinza, BoxInputCinza, InputStyledCinza, TextAreaStyledCinza } from './styles';
import IconsBark from '../../assets/icons/IconsBark';

export default class InputCinza extends Component {
    static contextType = ColorContext;

    constructor(props) {
        super(props);
    
        this.state = {
            focus:false,
            typeState:this.props.type,
        };
    
        this.onDigitando = this.onDigitando.bind(this);                
        this.onKeyUpText = this.onKeyUpText.bind(this);  
        this.onKeyUp = this.onKeyUp.bind(this);
        this.getIcon = this.getIcon.bind(this);      
        this.onFocus = this.onFocus.bind(this);
        this.onFocusOut = this.onFocusOut.bind(this);
        this.getButton = this.getButton.bind(this);
        this.clickViewPassword = this.clickViewPassword.bind(this);
    }

    clickViewPassword(){
        this.setState({typeState: this.state.typeState === "text" ? "password" : "text"});
    }

    onDigitando(e){
        if (this.props.onDigitando){
            this.props.onDigitando(e);
        }
        if (this.props.onChange){
            this.props.onChange(e);
        }
    }
  
    onKeyUpText(e){
        if (this.props.onKeyUpText){
            this.props.onKeyUpText(e);
        }
    }

    onKeyUp(e){
        let conteudo = e.target.value;  
        conteudo = conteudo.normalize('NFD');
        conteudo = Ferramentas.ReplaceAll(conteudo,/[\u0300-\u036f]/g, "").split("  ").join(" "); 
        e.target.value = conteudo; 
        if (this.props.onKeyUpText){
            this.props.onKeyUpText(e);
        }   
    }

    getIcon(){
        const {colors} = this.context;
        return this.props.type !== "textarea" && this.props.model === "cinza" ? 
                <BoxIconSearchCinza>
                    { this.props.icon === "search"  ? <IconsBark named="FiSearch" color={this.state.focus ? colors.primary : "#888888"} size={18}/> 
                        : this.props.icon === "user"  ? <IconsBark named="FiUser" color={this.state.focus ? colors.primary : "#888888"} size={18}/> 
                        : this.props.icon === "lock"  ? <IconsBark named="FiLock" color={this.state.focus ? colors.primary : "#888888"} size={18}/> 
                        : null}
                </BoxIconSearchCinza>
                : null;
    }

    getButton(){
        return this.props.onButtonClick ? 
                <ButtonCircle color="primary" onClick={this.props.onButtonClick}>
                    { this.props.icon === "search"  ? <IconsBark named="FiSearch" color={"white"} size={18}/> 
                        : this.props.icon === "user"  ? <IconsBark named="FiUser" color={"white"} size={18}/> 
                        : this.props.icon === "lock"  ? <IconsBark named="FiLock" color={"white"} size={18}/> 
                        : this.props.icon }
                </ButtonCircle>
                : this.props.type === "password" ? 
                <ButtonCircle color="primary" onClick={this.clickViewPassword}>
                    {this.state.typeState === "text" ? <IconsBark named="RiEyeFill" color={"white"} size={18}/> : <IconsBark named="RiEyeCloseLine" color={"white"} size={18}/> }
                </ButtonCircle>
                : null;
    }

    onFocus(){
        this.setState({focus:true});
    }

    onFocusOut(e){
        this.setState({focus:false});
    }

    // componentDidMount(){
    //     this.setState({typeState:this.props.type});
    // }

    render() {
        const {colors} = this.context;        
        return (
            <BoxInputCinza 
                hasButton={this.props.onButtonClick || this.props.type === "password"}
                focus={this.state.focus} 
                radius={this.props.radius}
                primary={colors.primary}>
                { this.getIcon() }                                
                { this.props.type === "textarea" ? 
                    <TextAreaStyledCinza 
                        onFocus={this.onFocus}
                        onBlur={this.onFocusOut}
                        primary={colors.primary}
                        radius={this.props.radius}
                        rows={this.props.rows} 
                        value={this.props.value} 
                        model={this.props.model}
                        onKeyUp={e => this.onKeyUp(e) } 
                        onChange={e => this.onDigitando(e)}      
                        />
                :
                    <InputStyledCinza 
                        style={{fontFamily:this.state.typeState==="password" ? "serif" : "inherit"}}
                        onFocus={this.onFocus}
                        onBlur={this.onFocusOut}
                        primary={colors.primary}
                        radius={this.props.radius}
                        model={this.props.model}
                        value={this.props.value} 
                        type={this.state.typeState}
                        onKeyUp={e => this.onKeyUp(e) } 
                        onChange={e => this.onDigitando(e)} 
                        id={this.props.id}
                        placeholder={this.props.placeholder}/>  
                } 
                { this.getButton() }    
                         
            </BoxInputCinza>  
        );
    }
}
