import React, { Component } from 'react';
import QuadroFotos from '../../QuadroFotos/QuadroFotos';
import CardText from '../../CardText/CardText';
import QuadroNumerico from '../../QuadroNumerico/QuadroNumerico';
import CardBarColor from '../../CardBarColor/CardBarColor';

export default class Etapa2 extends Component {
    render() {
        return (
            <div style={{ height: "100%", display: "grid", gap: "10px", gridTemplateColumns: "50% 25% 25%", marginRight:"25px" }}>
                <div>
                    <QuadroFotos
                        inactive={this.props.inactive}
                        onExcluir={this.props.toExcluir}
                        colunas={1}
                        passos={this.props.etapas}
                        data={this.props.densidade_quadro} />
                </div>
                <div style={{ height: "100%", display: "grid", gridTemplateRows: "auto auto 1fr" }}>
                    <div>
                        <div>
                            <QuadroNumerico titulo={'Area Acometida'}
                                detalhe={'Fios'}
                                texto={this.props.densidade_fios_acometida} />
                        </div>
                    </div>
                    <div>
                        <div>
                            <CardBarColor title='Local de Estudo'
                                image={'topo-vazio'}
                                Save={this.props.DensidadeAcometida64}
                                ImagemDefault={this.props.ImagemDefaultAcometida("2", "sim")}
                                value={this.props.densidade_fios_acometida_custom}
                                onChangeValue={(v) => this.props.inactive ? null : this.props.onDensidade_fios_acometida_custom(v)}
                                marks={this.props.marksDensidade} />
                        </div>
                    </div>
                    <div>
                        <div style={{ height: "100%" }}>
                            <CardText placeholder={'Digite aqui...'}
                                height={'100%'}
                                value={this.props.densidade_obs1}
                                onChange={this.props.toChangeTextObsv1}
                                titulo={'Parecer Médico Area Acometida'} />
                        </div>
                    </div>
                </div>
                <div style={{ height: "100%", display: "grid", gridTemplateRows: "auto auto 1fr" }}>
                    <div>
                        <div>
                            <QuadroNumerico titulo={'Area Não Acometida'}
                                detalhe={'Fios'}
                                texto={this.props.densidade_fios_nao_acometida} />
                        </div>
                    </div>
                    <div>
                        <div>
                            <CardBarColor title='Local de Estudo'
                                image={'atras-vazio'}
                                ImagemDefault={this.props.ImagemDefaultAcometida("2", "nao")}
                                Save={this.props.DensidadeNaoAcometida64}
                                value={this.props.densidade_fios_nao_acometida_custom}
                                onChangeValue={(v) => this.props.inactive ? null : this.props.onDensidade_fios_nao_acometida_custom(v)}
                                marks={this.props.marksDensidade} />
                        </div>
                    </div>
                    <div>
                        <div style={{ height: "100%" }}>
                            <CardText placeholder={'Digite aqui...'}
                                height={'100%'}
                                value={this.props.densidade_obs2}
                                onChange={this.props.toChangeTextObsv2}
                                titulo={'Parecer Médico Area Não Acometida'} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};