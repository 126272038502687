import styled from "styled-components";

export const ButtonStyled = styled.button`
  background-color: ${props => props.disabled !== true ? props.color === "primary" ? props.primary : "white" : props.secondary}; 
  border: ${props => props.disabled !== true ? "2px solid "+props.primary : "2px solid "+props.secondary};
  border-radius: 20px;
  color: ${props => props.color === "primary" ? "white" : props.primary};  
  padding-right: ${props => props.pad === null || props.pad === undefined || props.pad === true ? "20px" : "0px"};
  padding-left: ${props => props.pad === null || props.pad === undefined || props.pad === true ? "20px" : "0px"};
  display: flex;
  justify-content: center;
  align-items: center;  
  width: ${props => props.block ? "100%" : "auto"};

  :focus {
    outline: none !important;
    border: ${props => "2px solid "+props.secondary};
    background-color: ${props => props.secondary}; 
  }

  :hover {
    border:${props => "2px solid "+props.secondary};
  }
`