import React, { Component } from 'react';
import CardBarColor from '../../CardBarColor/CardBarColor';
import { Radar } from 'react-chartjs-2';

export default class Etapa5 extends Component {
    render() {
        return (
            <div style={{ height: "100%", display: "grid", gap: "10px", gridTemplateRows: "auto auto auto auto auto" }}>

                <h4 style={{ textAlign: "center", height: "5px" }}>Conclusão Final</h4>

                <div style={{ display: "grid", gap: "10px", gridTemplateColumns: "50% 1fr" }}>
                    <div>
                        <div className="chart-wrapper">
                            <Radar
                                options={{
                                    scale: {
                                        ticks: {
                                            beginAtZero: true,
                                            max: 100,
                                            min: 0,
                                            stepSize: 25
                                        }
                                    },
                                }}
                                data={{
                                    labels: [this.props.etapas[1].label,
                                    this.props.etapas[2].label,
                                    [this.props.etapas[3].label, 'Oleosidade'],
                                    [this.props.etapas[3].label, 'Escamação'],
                                    [this.props.etapas[3].label, 'Inflamação'],
                                    this.props.etapas[4].label,
                                    ],
                                    datasets: [
                                        {
                                            label: 'Conclusão Final',
                                            backgroundColor: 'rgba(32,168,216,0.2)',
                                            borderColor: 'rgba(32,168,216,1)',
                                            pointBackgroundColor: 'rgba(32,168,216,1)',
                                            pointBorderColor: '#fff',
                                            pointHoverBackgroundColor: '#fff',
                                            pointHoverBorderColor: 'rgba(32,168,216,1)',
                                            data: [this.props.analise_nota,
                                            this.props.densidade_fios_acometida_custom,
                                            this.props.couro_nota2,
                                            this.props.couro_nota3,
                                            this.props.couro_nota4,
                                            this.props.espessura_micro_acometida_custom],
                                        },
                                    ],

                                }} />
                        </div>
                    </div>
                    <div style={{ display: "grid", gap: "10px", gridTemplateRows: "auto auto auto auto" }}>
                        <div>
                            <CardBarColor title='Espessura Área Acometida'
                                limpo={true}
                                value={this.props.espessura_micro_acometida_custom}
                                max={30}
                                marks={this.props.marksEspessura} />
                        </div>
                        <div>
                            <CardBarColor title='Densidade Área Acometida'
                                limpo={true}
                                value={this.props.densidade_fios_acometida_custom}
                                max={30}
                                marks={this.props.marksEspessura} />
                        </div>
                        <div>
                            <CardBarColor title='Espessura Área Não Acometida'
                                limpo={true}
                                value={this.props.espessura_micro_nao_acometida_custom}
                                max={30}
                                marks={this.props.marksEspessura} />
                        </div>
                        <div>
                            <CardBarColor title='Densidade Área Não Acometida'
                                limpo={true}
                                value={this.props.densidade_fios_nao_acometida_custom}
                                max={30}
                                marks={this.props.marksEspessura} />
                        </div>
                    </div>
                </div>

                <h4 style={{ textAlign: "center", height: "5px" }}>Parecer Médico</h4>

                <div style={{ display: "grid", gap: "10px", gridTemplateColumns: "50% 50%" }}>
                    <div>
                        <h6>Grau de Alopécia</h6>
                        <p>{this.props.getObservacaoConclusao(this.props.analise_obs)}</p>
                    </div>
                    <div>
                        <h6>Densidade Folicular</h6>
                        <p>{this.props.getObservacaoConclusao(this.props.densidade_obs1)}</p>
                    </div>
                </div>
                <div style={{ display: "grid", gap: "10px", gridTemplateColumns: "50% 50%" }}>
                    <div>
                        <h6>Analise Couro Cabeludo</h6>
                        <p>{this.props.getObservacaoConclusao(this.props.couro_obs1)}</p>
                    </div>
                    <div>
                        <h6>Espessura dos Fios</h6>
                        <p>{this.props.getObservacaoConclusao(this.props.espessura_obs1)}</p>
                    </div>
                </div>
            </div>
        )
    }
};