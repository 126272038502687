import React, { Component } from 'react';
import ColorContext from '../../ColorContext';
import { BoxSelect, BoxText, Container } from './styles';

export default class CheckSelect extends Component {    
    static contextType = ColorContext;

    constructor(props) {
        super(props);

        this.onSelected = this.onSelected.bind(this);
    }

    onSelected(value){
        if (this.props.onSelected){
            this.props.onSelected({target:{value:this.props.value}});
        }
    }

    render() {
        const {colors} = this.context;
        return (
            <Container onClick={this.onSelected}>
                <BoxSelect selected={this.props.checked} primary={colors.primary}></BoxSelect>
                 <BoxText  color={"black"}>{this.props.children}</BoxText>
            </Container>  
        );
    }
}
