import styled from "styled-components";
import { Dropdown } from "rsuite";

export const DropdownMenuStyled = styled(Dropdown.Item)`
  border-radius: 20px;
  width: 250px;
`

export const DropDowStyled = styled(Dropdown)`
  margin: 0px;
  padding: 0px;
`

export const DropdownToggleStyled = styled.div`
  padding: 0px;
  width: 100%;
  background-color: white;
  border-radius: 20px;
  display: flex;
  width: -webkit-fill-available;
  border: ${props => props.secondary ? "2px solid " + props.secondary : "2px solid #F5F5F5"};
  font-size: 15px;  
  padding-left: 8px;
  padding-right: 8px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;  
  color: ${props => props.primary};

  :focus{
    box-shadow: 0 0 0 0 rgb(32 168 216 / 25%);
    outline: none !important;
    border: ${props => props.secondary ? "2px solid " + props.secondary : "2px solid " + props.primary};
    background-color: white;    
    border-radius: 20px;    
  }
`

export const DropdownItemStyled = styled(Dropdown.Item)`
  border-radius: ${props => props.header ? "20px 20px 0px 0px" : props.last ? "0px 0px 20px 20px" : "0px"};
  background-color: white;
  color:${props => props.disabled ? "#73818f" : "#23282c"};
  width: 100%;
  border: 0px solid #c8ced3; 
  border-bottom: 1px solid #c8ced3;
  padding: 10px 20px;
  text-align: left;

  :hover {
    background-color: #F0F3F5;
  }
`

export const BarkDropDown = styled.div`
  > div {
    > span {
      width: 100%;
    }
  };
  min-width: 50px;
`

export const TextoPrincipal = styled.p`
  margin: 0px;
  width: 100%;
  text-align: left;  
  color: ${props => props.secondary ? props.secondary : "black"};
` 
export const BoxItemMenu = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;  
`