import React, { Component } from 'react';
import QuadroFotos from '../../QuadroFotos/QuadroFotos';
import CardText from '../../CardText/CardText';
import QuadroNumerico from '../../QuadroNumerico/QuadroNumerico';
import CardBarColor from '../../CardBarColor/CardBarColor';

export default class Etapa4 extends Component {
    render() {
        return (
            <div style={{ height: "100%", display: "grid", gap: "10px", gridTemplateColumns: "50% 25% 25%" , marginRight:"25px" }}>
                <div>          
                    <QuadroFotos
                        inactive={this.props.inactive}
                        onExcluir={this.props.onExcluir}
                        colunas={1}
                        passos={this.props.etapas}
                        data={this.props.espessura_quadro} />    
                </div>
                <div style={{ height: "100%", display: "grid", gridTemplateRows: "auto auto 1fr" }}>
                    <div>
                        <QuadroNumerico titulo={'Area Acometida'}
                            detalhe={'µm'}
                            texto={this.props.espessura_micro_acometida} />
                    </div>
                    <div>
                        <CardBarColor title='Local de Estudo'
                            image={'topo-vazio'}
                            ImagemDefault={this.props.ImagemDefaultAcometida("4", "sim")}
                            value={this.props.espessura_micro_acometida_custom}
                            onChangeValue={(v) => this.props.inactive ? null : this.props.onEspessura_micro_acometida_custom(v)}
                            Save={this.props.EspessuraAcometida64}
                            max={100}
                            marks={this.props.marksEspessura} />

                    </div>
                    <div style={{ height: "100%" }}>
                        <CardText placeholder={'Digite aqui...'}
                            height={'10vh'}
                            value={this.props.espessura_obs1}
                            onChange={this.props.toChangeTextObsv1}
                            titulo={'Parecer Médico Area Acometida'} />
                    </div>
                </div>
                <div style={{ height: "100%", display: "grid", gridTemplateRows: "auto auto 1fr" }}>
                    <div>
                        <QuadroNumerico titulo={'Area Não Acometida'}
                            detalhe={'µm'}
                            texto={this.props.espessura_micro_nao_acometida} />
                    </div>
                    <div>
                        <CardBarColor title='Local de Estudo'
                            image={'atras-vazio'}
                            ImagemDefault={this.props.ImagemDefaultAcometida("4", "nao")}
                            Save={this.props.EspessuraNaoAcometida64}
                            value={this.props.espessura_micro_nao_acometida_custom}
                            onChangeValue={(v) => this.props.inactive ? null : this.props.onEspessura_micro_nao_acometida_custom(v)}
                            marks={this.props.marksEspessura} />
                    </div>
                    <div style={{ height: "100%" }}>
                        <CardText placeholder={'Digite aqui...'}
                            height={'10vh'}
                            value={this.props.espessura_obs2}
                            onChange={this.props.toChangeTextObsv2}
                            titulo={'Parecer Médico Area Não Acometida'} />

                    </div>
                </div>
            </div>
        )
    }
};