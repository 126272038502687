import React, {Component} from 'react';
import { Container } from './styles';

export default class CardFooterBark extends Component
{ 
    render()
    {
        return (  
            <Container {...this.props}>
                {this.props.children}
            </Container>                                                     
        )
    }
};