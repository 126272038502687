import React, { Component } from 'react';
import FirebaseService from '../../service/FirebaseService';
import { Titulo, RowStyled, RowOptions, DivPergunta, ContainerTotal } from './styles';
import Ferramentas from '../../classes/ferramentas';
import Loading from '../Base/Loading/Loading';
import ButtonCircle from '../ButtonCircle/ButtonCircle';
import CheckSelect from '../CheckSelect/CheckSelect';
import RadioSelect from '../RadioSelect/RadioSelect';
import ColorContext from '../../ColorContext';
import BoxAlert from '../BoxAlert/BoxAlert';
import { RollBack } from '../Base/RollBack/RollBack';
import CardGroupBark from '../Base/CardGroupBark/CardGroupBark';
import CardBark from '../Base/CardBark/CardBark';
import CardBodyBark from '../Base/CardBodyBark/CardBodyBark';
import ColBark from '../Base/ColBark/ColBark';
import RowBark from '../Base/RowBark/RowBark';
import InputCinza from '../InputCinza/InputCinza';
import { FadingDiv } from '../Base/FadingDiv/styles';
import { TitleForm } from '../Base/TitleForm/styles';

export default class EditarAnaminese extends Component {
    static contextType = ColorContext;

    constructor(props) {
        super(props);

        this.state = {
            invalido: { status: false, message: '' },
            conta: [],
            perguntas: [],
            concluido: false,
            tratamento: false,
            idCliente: 0,
            loading: true,
            columnR: [0, 1, 2, 3, 5, 7, 8, 10],
            msgFinish: false,
        };

        this.criarConta = this.criarConta.bind(this);
        this.preencheCampos = this.preencheCampos.bind(this);
        this.getVisivel = this.getVisivel.bind(this);
        this.getResposta = this.getResposta.bind(this);
        this.createItem = this.createItem.bind(this);
        this.montagemColunas = this.montagemColunas.bind(this);
    }

    criarConta() {
        this.setState({ loading: true });
        FirebaseService.GravarAnaminese(this.state.conta.filter((item) => item !== null), (retorno) => {
            if (retorno.status.toString() === "0") {
                if (this.props.Externo) {
                    this.props.Proxima();
                } else {
                    window.location.href = (`#/${Ferramentas.Slug()}/lista/ItemCliente/${this.state.idCliente}`);
                }
            } else {
                this.setState({ invalido: { status: true, message: retorno.retorno }, loading: false })
            }
        });
    }

    preencheCampos(e, campo, resposta) {
        const pergunta = this.state.perguntas.filter((item) => item.id.toString() === campo.toString())
        if (pergunta.length === 0) {
            return;
        }
        let newConta = this.state.conta;
        if ((pergunta[0].tipo === "check") || (pergunta[0].tipo === "text") || (pergunta[0].tipo === "text-right") || (pergunta[0].tipo === "multi" && e.target.checked === false)) {
            for (const key in newConta) {
                if (resposta && pergunta[0].tipo === "multi") {
                    if ((newConta[key].id_pergunta.toString() === campo.toString()) && (newConta[key].resposta.toString() === resposta.toString())) {
                        delete newConta[key];
                        break;
                    }
                } else if (newConta[key].id_pergunta.toString() === campo.toString()) {
                    delete newConta[key];
                    break;
                }
            }
        }
        if ((pergunta[0].tipo === "check") || (pergunta[0].tipo === "text") || (pergunta[0].tipo === "text-right") || (pergunta[0].tipo === "multi" && e.target.checked)) {
            newConta.push({
                id_pergunta: campo.toString(),
                resposta: e.target.value,
                id_cliente: this.state.idCliente,
            });
        }
        let novoTratamento = this.state.tratamento;
        if (campo.toString() === "6") {
            novoTratamento = e.target.value.toString() === "14"
        }
        this.setState({ conta: newConta, tratamento: novoTratamento });
    }

    componentDidMount() {
        const hashes = window.location.hash;
        let cliente = hashes.split('/')[3];
        this.setState({ loading: true, idCliente: this.props.ClienteExterno ? this.props.ClienteExterno : cliente }, () => {

            FirebaseService.AnaminesePerguntas((retorno) => {
                this.setState({ perguntas: retorno }, () => {
                    FirebaseService.AnamineseRespostas(this.state.idCliente, (retorno) => {
                        let i = 0;
                        while (i !== retorno.length) {
                            this.preencheCampos({ target: { value: retorno[i].resposta, checked: true } }, retorno[i].id_pergunta.toString(), retorno[i].resposta.toString())
                            i++;
                        }
                        this.setState({ loading: false });
                    });
                });
            });

            const params = window.location.hash.split("/");
            if ((params.length > 1) && (params[1] !== "")) {
                const slug = params[1];
                this.setState({ slug: slug });
                FirebaseService.getValidExists(slug, (result) => {
                    localStorage.setItem("salao_id", result.id);
                    const { setColor } = this.context;
                    setColor(result.cor_primaria, result.cor_secundaria);
                    this.setState({ primaria: result.cor_primaria, secundaria: result.cor_secundaria });
                }, () => { });
            }
        });
    }

    getVisivel(_id) {
        return _id === 7 || _id === 8 || _id === 9 ? this.state.tratamento : true;
    }

    getResposta(_pergunta, _resposta) {
        let retorno = '';
        if (this.state.conta.length > 0) {
            let existe = this.state.conta.filter((item) => item.id_pergunta.toString() === _pergunta.toString());
            if (existe.length === 1) {
                retorno = existe[0].resposta;
            } else if (existe.length > 1) {
                let responde = existe.filter((item) => item.resposta.toString() === _resposta.toString());
                if (responde.length === 1) {
                    retorno = responde[0].resposta;
                }
            }
        }
        return retorno;
    }

    montagemColunas(lista, callback) {
        let retorno = [];
        let i = 0;
        while (i !== lista.length) {
            if ([0, 1, 2, 7].includes(i)) {
                let primeiro = lista[i];
                let segundo = null;
                retorno.push(callback(primeiro, segundo));
            } else if (this.state.columnR.includes(i)) {
                let primeiro = lista[i];
                let segundo = null;
                try {
                    segundo = lista[i + 1];
                } catch (e) { }
                retorno.push(callback(primeiro, segundo));
            }
            i++;
        }
        return retorno;
    }

    createItem(item) {
        return (
            <DivPergunta visivel={this.getVisivel(item.id)}>
                {item.tipo !== "text-right" ? <Titulo>{item.pergunta}</Titulo> : null}
                {item.tipo === "check" ?
                    <RowOptions>
                        {item.respostas.map((resposta) => {
                            return (

                                <CheckSelect
                                    onSelected={(e) => this.preencheCampos(e, resposta.id_pergunta.toString())}
                                    checked={this.getResposta(resposta.id_pergunta).toString() === resposta.id.toString()}
                                    value={resposta.id}
                                    group={"inline-radio" + resposta.id_pergunta}
                                    id={resposta.id}>{resposta.resposta}</CheckSelect>

                            )
                        })}
                    </RowOptions>
                    : item.tipo === "multi" ?
                        <RowOptions>
                            {item.respostas.map((resposta) => {
                                return (

                                    <RadioSelect
                                        onSelected={(e) => this.preencheCampos(e, resposta.id_pergunta.toString(), resposta.id.toString())}
                                        checked={this.getResposta(resposta.id_pergunta.toString(), resposta.id.toString()).toString() === resposta.id.toString()}
                                        value={resposta.id}
                                        group={"inline-radio" + resposta.id_pergunta}
                                        id={resposta.id}>{resposta.resposta}</RadioSelect>

                                )
                            })}
                        </RowOptions>
                        : item.tipo === "text-right" ?
                            <RowOptions>
                                {/* <FormGroup check inline>
                                    <InputGroup>
                                        <Input placeholder="1" type="text" onChange={(e) => this.preencheCampos(e, item.id)} value={this.getResposta(item.id).toString()} />
                                        <InputGroupAddon addonType="append">{item.pergunta}</InputGroupAddon>
                                    </InputGroup>
                                </FormGroup> */}

                                <InputCinza
                                    type="text"
                                    placeholder="1"
                                    value={this.getResposta(item.id).toString()}
                                    onChange={(e) => this.preencheCampos(e, item.id)} 
                                    onButtonClick={e=>console.log(e)}
                                    icon={item.pergunta}
                                    />
                            </RowOptions>
                            : item.tipo === "text" ?
                                <InputCinza type="text" onChange={(e) => this.preencheCampos(e, item.id)} value={this.getResposta(item.id).toString()} />
                                : null
                }
            </DivPergunta>
        )
    }

    render() {
        return (
            <ContainerTotal primaria={this.state.primaria} secundaria={this.state.secundaria}>
                <>
                    <Loading active={this.state.loading} />
                    <RowStyled>
                        <ColBark xs={24} sm={12} lg={6}></ColBark>
                        <ColBark xs={24} sm={12} lg={12}>
                            <RowBark>
                                <ColBark xs={24} sm={12} lg={12}>
                                    {this.props.Externo ? null : <RollBack to={`/${Ferramentas.Slug()}/lista/ItemCliente/${this.state.idCliente}`} />}
                                    <br />
                                </ColBark>
                            </RowBark>
                            <RowBark>
                                <CardGroupBark>
                                    <CardBark>
                                        <CardBodyBark>
                                            <RowBark>
                                                <ColBark lg={24}>
                                                    <TitleForm>Anaminese</TitleForm>
                                                    <RowBark>
                                                        <ColBark xs={24}>
                                                            <BoxAlert style={{ marginTop: '20px' }} open={this.state.invalido.status} color="danger">
                                                                {this.state.invalido.message}
                                                            </BoxAlert>
                                                        </ColBark>
                                                    </RowBark>
                                                    <FadingDiv>
                                                        {this.montagemColunas(this.state.perguntas, (primeiro, segundo) => {
                                                            return (
                                                                <RowBark>
                                                                    <ColBark lg={12}>
                                                                        {this.createItem(primeiro)}
                                                                    </ColBark>
                                                                    <ColBark lg={12}>
                                                                        {segundo ? this.createItem(segundo) : null}
                                                                    </ColBark>
                                                                </RowBark>
                                                            )
                                                        })}
                                                    </FadingDiv>
                                                </ColBark>
                                            </RowBark>
                                            <RowBark>
                                                <ColBark xs={24} style={{marginTop:"20px"}}>
                                                    {this.state.perguntas.length > 0 ?
                                                        <ButtonCircle onClick={this.criarConta} color="primary">Próximo</ButtonCircle>
                                                        : null}
                                                </ColBark>
                                            </RowBark>
                                        </CardBodyBark>
                                    </CardBark>
                                </CardGroupBark>
                            </RowBark>
                        </ColBark>
                        <ColBark xs={24} sm={12} lg={6}></ColBark>
                    </RowStyled>
                </>
            </ContainerTotal>
        )
    }
};
