import React, {Component} from 'react';
import EditarAnaminese from '../EditarAnaminese/EditarAnaminese';
import NovoCliente from '../NovoCliente/NovoCliente';
import ColorContext from '../../ColorContext';
import MensagemFinal from '../MensagemFinal/MensagemFinal';

export default class ClienteExterno extends Component
{
    static contextType = ColorContext;

    constructor(props) {
        super(props);

        this.state = {            
            andamento:0,
        };

        this.proximaEtapa = this.proximaEtapa.bind(this);
    }  

    proximaEtapa = (id) => {
        const atual = this.state.andamento + 1;
        this.setState({andamento:atual,cliente:id});
    }

    render() {
        return (
            <>
             { this.state.andamento === 0 
             ? <NovoCliente Externo={true} Proxima={this.proximaEtapa}/> 
             : this.state.andamento === 1 ? <EditarAnaminese ClienteExterno={this.state.cliente} Externo={true} Proxima={this.proximaEtapa}/> 
             : <MensagemFinal/>  
          
         }
            </>
        )
    }
};