import React, { Component } from 'react';
import ColorContext from '../../ColorContext';
import ButtonModal from '../Buttons/Modal/ButtonModal';
import ViewModal from '../ViewModal/ViewModal';
import { Box, BoxButtons, BoxCenterImage, BoxTitle, ImageStyled } from './styles';
import Ferramentas from '../../classes/ferramentas';
import IconsBark from '../../assets/icons/IconsBark';

export default class QuadroFotos extends Component {
    static contextType = ColorContext;

    constructor(props) {
        super(props);

        this.getLinhas = this.getLinhas.bind(this);
        this.getCardImage = this.getCardImage.bind(this);
        this.getButtons = this.getButtons.bind(this);
        this.getImagem = this.getImagem.bind(this);
        this.onExclusao = this.onExclusao.bind(this);
        this.getPaddingUsing = this.getPaddingUsing.bind(this);
    }

    dentroButtonView() {
        let retorno = [];
        retorno.push(<IconsBark key={retorno.length} named="FaRegEye" size={18} />);
        return retorno;
    }

    conteudoRodape(obj) {
        let retorno = '';
        let chave = JSON.parse(obj.chave);
        if ((chave.etapa === 2) && (obj.nota > 0)) {
            retorno = obj.nota + ' Fios';
        } else if ((chave.etapa === 4) && (obj.nota > 0)) {
            retorno = obj.nota + ' μ';
        }
        return retorno;
    }

    PerguntaExclusao(chave) {
        let obj = JSON.parse(chave);
        let retorno = [];
        retorno.push(<div key={retorno.length}>Deseja realmente excluir esta Foto ? <br /><strong>Etapa:</strong> {this.props.passos[obj.etapa - 1].label} <br /> <strong>Foto:</strong> {this.props.passos[obj.etapa - 1].tipos[obj.tipo - 1]}</div>);
        return retorno;
    }

    dentroButton() {
        let retorno = [];
        retorno.push(<IconsBark key={retorno.length} named="FaRegTrashAlt" size={18} />);
        return retorno;
    }

    onExclusao(chave) {
        this.props.onExcluir(chave);
    }

    getImagem(obj) {
        let retorno = [];
        if (obj.vazio) {
            if (this.props.inactive) {
                return retorno;
            }
            retorno.push(
                <BoxCenterImage key={retorno.length}>
                    {/* <a style={{ textDecoration:"none" }} href={obj.vazio}>                   
                        <img src={obj.image} alt="imagem nao encontrada"/>                            
                    </a> */}
                    <a style={{ textDecoration: "none" }} href={obj.vazio}>
                        {obj.image}
                    </a>
                </BoxCenterImage>
            );
        } else {
            retorno.push(
                <BoxCenterImage key={retorno.length}>
                    <ImageStyled src={obj.image} alt="imagem nao encontrada" />
                    <BoxButtons>
                        {this.getButtons(obj)}
                    </BoxButtons>
                </BoxCenterImage>
            );
        }
        return retorno;
    }

    getButtons(obj) {
        let retorno = [];
        if ((obj.image === undefined) || (obj.vazio)) {
            if (this.props.inactive) {
                return retorno;
            }
        } else {
            retorno.push(
                <ViewModal
                    key={retorno.length}
                    size="sm"
                    color="outline-primary"
                    initialModalState={false}
                    titulo={obj.titulo}
                    chave={obj.chave}
                    onYes={e => this.onExclusao(e)}
                    foto={obj.image}
                    desenhada={obj.desenhada}
                    rodape={this.conteudoRodape(obj)}
                    inButton={this.dentroButtonView()} />
            );
            retorno.push('\u00A0'); retorno.push('\u00A0');
            if (this.props.inactive) {
                return retorno;
            }
            if (this.props.onExcluir) {
                retorno.push(
                    <ButtonModal
                        key={retorno.length}
                        size="sm"
                        color="outline-primary"
                        initialModalState={false}
                        chave={obj.chave}
                        onYes={e => this.onExclusao(e)}
                        texto={this.PerguntaExclusao(obj.chave)}
                        inButton={this.dentroButton()} />
                );
                retorno.push('\u00A0'); retorno.push('\u00A0');
            }
        }
        return retorno;
    }

    getCardImage(obj, RemovePaddingCol) {
        const { colors } = this.context;
        let retorno = [];
        if (obj) {
            retorno.push(            
                <Box key={retorno.length}  altura={this.props.altura} window={window.screen.height >= 1080}>
                    <BoxTitle primary={colors.primary}> {obj.titulo}</BoxTitle>
                    {this.getImagem(obj)}
                </Box>                
            );
        }
        return retorno;
    }

    getPaddingUsing(right, left, bottom, top) {
        let retorno = {};
        if (Ferramentas.getMonitor()) {
            if (right) retorno.paddingRight = '0.5%';
            if (left) retorno.paddingLeft = '0.5%';
            if (top) retorno.paddingTop = '0.5%';
            if (bottom) retorno.paddingBottom = '0.5%';
        }
        return retorno;
    }

    getLinhas() {
        let linhas = Math.round(this.props.data.length / this.props.colunas);
        if ((this.props.data.length / this.props.colunas).toString().indexOf('.') > -1) {
            linhas++;
        }
        let retorno = [];
        if (linhas > 0) {
            let i = 0;
            let posicao = 0;
            while (i !== linhas) {
                if (this.props.colunas === 4) {
                    retorno.push(
                        <div style={{height:`calc( 100% / ${linhas})`, display:"grid", gridTemplateColumns:"1fr 1fr 1fr 1fr", gap:"10px", marginTop:i!==0?"10px":"0px"}} key={retorno.length}>
                            {this.getCardImage(this.props.data[posicao++], this.getPaddingUsing(true, false, false, false))}
                            {this.getCardImage(this.props.data[posicao++], this.getPaddingUsing(true, true, false, false))}
                            {this.getCardImage(this.props.data[posicao++], this.getPaddingUsing(true, true, false, false))}
                            {this.getCardImage(this.props.data[posicao++], this.getPaddingUsing(false, true, false, false))}
                        </div>
                    );
                } else if (this.props.colunas === 2) {
                    retorno.push(
                        <div style={{height:`calc( 100% / ${linhas})`, display:"grid", gridTemplateColumns:"1fr 1fr", gap:"10px", marginTop:i!==0?"10px":"0px"}} key={retorno.length}>
                            {this.getCardImage(this.props.data[posicao++], this.getPaddingUsing(true, false, true, true))}
                            {this.getCardImage(this.props.data[posicao++], this.getPaddingUsing(false, true, true, true))}
                        </div>
                    );
                } else if (this.props.colunas === 1) {
                    retorno.push(
                        <div style={{height:`calc( 100% / ${linhas})`, display:"grid", gridTemplateColumns:"1fr", marginTop:i!==0?"10px":"0px"}} key={retorno.length}>
                            {this.getCardImage(this.props.data[posicao++], {})}
                        </div>
                    );
                }
                i++;
            }
        }
        return retorno;
    }

    render() { return this.getLinhas() }
};