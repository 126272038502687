import React, { Component } from 'react';
import BoxAlert from '../BoxAlert/BoxAlert';
import FirebaseService from '../../service/FirebaseService';
import { Titulo, RowStyled, PanelButton, ContainerTotal, BoxRollBack } from './styles';
import Ferramentas from '../../classes/ferramentas';
import Loading from '../Base/Loading/Loading';
import InputCinza from '../InputCinza/InputCinza';
import ButtonCircle from '../ButtonCircle/ButtonCircle';
import ComboBoxCinza from '../ComboBoxCinza/ComboBoxCinza';
import ColorContext from '../../ColorContext';
import { RollBack } from '../Base/RollBack/RollBack';
import CardBodyBark from '../Base/CardBodyBark/CardBodyBark';
import CardBark from '../Base/CardBark/CardBark';
import CardGroupBark from '../Base/CardGroupBark/CardGroupBark';
import ColBark from '../Base/ColBark/ColBark';
import RowBark from '../Base/RowBark/RowBark';
import { FadingDiv } from '../Base/FadingDiv/styles';
import { TitleForm } from '../Base/TitleForm/styles';

export default class EditaCliente extends Component {
    static contextType = ColorContext;

    constructor(props) {
        super(props);

        this.state = {
            invalido: { status: false, message: '' },
            conta: { sexo: "M" },
            concluido: false,
            id: 0,
            loading: true,
        };

        this.atualizarConta = this.atualizarConta.bind(this);
        this.preencheCampos = this.preencheCampos.bind(this);
        this.getCEP = this.getCEP.bind(this);
    }

    componentDidMount() {
        const v = this.props.match.params.cliente;
        if (v) {
            this.setState({ id: v, loading: true });

            FirebaseService.GetUF((status, result) => {

                let i = 0;
                while (i !== result.length) {
                    result[i].key = result[i].sigla;
                    result[i].value = result[i].nome;
                    i++;
                }

                this.setState({ uf: result });

                FirebaseService.getDataCliente(v, (dataReceived) => {
                    this.setState({ details: dataReceived });

                    let newConta = this.state.conta;
                    if (dataReceived.data_nasc) {
                        newConta["data_nasc"] = dataReceived.data_nasc;
                    }
                    newConta["nome"] = dataReceived.nome;
                    newConta["cpf"] = dataReceived.cpf;
                    newConta["sexo"] = dataReceived.sexo;
                    newConta["observacao"] = dataReceived.observacao ? dataReceived.observacao : "";
                    newConta["email"] = dataReceived.email;
                    newConta["celular"] = dataReceived.celular ? dataReceived.celular : "";
                    newConta["id_estabelecimento"] = localStorage.getItem("salao_id");
                    newConta["id"] = dataReceived.id;
                    newConta["cep"] = dataReceived.cep ? dataReceived.cep : "";
                    newConta["numero"] = dataReceived.numero ? dataReceived.numero : "";
                    newConta["endereco"] = dataReceived.endereco ? dataReceived.endereco : "";
                    newConta["bairro"] = dataReceived.bairro ? dataReceived.bairro : "";
                    newConta["cidade"] = dataReceived.cidade ? dataReceived.cidade : "";
                    newConta["estado"] = dataReceived.estado ? dataReceived.estado : "";
                    this.setState({ conta: newConta, loading: false });
                });
            });

            const params = window.location.hash.split("/");
            if ((params.length > 1) && (params[1] !== "")) {
                const slug = params[1];
                this.setState({ slug: slug });
                FirebaseService.getValidExists(slug, (result) => {
                    localStorage.setItem("salao_id", result.id);
                    const { setColor } = this.context;
                    setColor(result.cor_primaria, result.cor_secundaria);
                    this.setState({ primaria: result.cor_primaria, secundaria: result.cor_secundaria });
                }, () => { });
            }
        }
    }

    getCEP() {
        this.setState({ loading: true });
        FirebaseService.GetCEP(this.state.conta.cep, (status, retorno) => {
            if (status.toString() === "0") {
                let newConta = this.state.conta;
                newConta["endereco"] = retorno.logradouro;
                newConta["bairro"] = retorno.bairro;
                newConta["cidade"] = retorno.cidade;
                newConta["estado"] = retorno.uf;
                this.setState({ conta: newConta, loading: false });
            } else {
                this.setState({ invalido: { status: true, message: "problemas para buscar cep" }, loading: false })
            }
        });
    }

    atualizarConta() {
        this.setState({ loading: true });
        FirebaseService.EditarCliente(this.state.conta, (retorno) => {
            if (retorno.status.toString() === "0") {
                window.location.href = "#/" + Ferramentas.Slug() + "/lista/ItemCliente/" + this.state.id.toString();
            } else {
                this.setState({ invalido: { status: true, message: retorno.retorno }, loading: false })
            }
        });
    }

    preencheCampos(e, campo) {
        let newConta = this.state.conta;
        newConta[campo] = e.target.value;
        newConta["id_estabelecimento"] = localStorage.getItem("salao_id");
        this.setState({ conta: newConta });
    }

    render() {
        return (
            <ContainerTotal primaria={this.state.primaria} secundaria={this.state.secundaria}>
                <Loading active={this.state.loading} />
                <RowStyled>
                    <ColBark xs={24} sm={12} lg={6}></ColBark>
                    <ColBark xs={24} sm={12} lg={12}>
                        <RowBark>
                            <ColBark xs={24} sm={12} lg={12}>
                                <BoxRollBack>
                                    <RollBack to={`/${Ferramentas.Slug()}/lista/ItemCliente/${this.state.id}`} />
                                </BoxRollBack>
                            </ColBark>
                        </RowBark>
                        <RowBark>
                            <CardGroupBark>
                                <CardBark>
                                    <CardBodyBark>
                                        <RowBark>
                                            <ColBark lg={24}>
                                                <TitleForm>Cadastrar Paciente</TitleForm>
                                                <RowBark>
                                                    <ColBark xs={24}>
                                                        <BoxAlert style={{ marginTop: '20px' }} open={this.state.invalido.status} color="danger">
                                                            {this.state.invalido.message}
                                                        </BoxAlert>
                                                    </ColBark>
                                                </RowBark>
                                                <FadingDiv>
                                                    <Titulo>Nome Completo</Titulo>
                                                    <InputCinza value={this.state.conta.nome} type="text" onChange={e => this.preencheCampos(e, "nome")} />
                                                    <RowBark>
                                                        <ColBark xs={6} sm={6} lg={6}>
                                                            <Titulo>Data de Nascimento</Titulo>
                                                            <InputCinza type="date" value={this.state.conta.data_nasc} onChange={e => this.preencheCampos(e, "data_nasc")} />
                                                        </ColBark>
                                                        <ColBark xs={6} sm={6} lg={6}>
                                                            <Titulo>CPF</Titulo>
                                                            <InputCinza type="text" value={this.state.conta.cpf} onChange={e => this.preencheCampos(e, "cpf")} />
                                                        </ColBark>
                                                        <ColBark xs={6} sm={6} lg={6}>
                                                            <Titulo>Sexo</Titulo>
                                                            <ComboBoxCinza
                                                                onChange={e => this.preencheCampos(e, "sexo")}
                                                                value={this.state.conta.sexo}
                                                                list={[{ key: "M", value: "Masculino" }, { key: "F", value: "Feminino" }]}
                                                            >
                                                            </ComboBoxCinza>
                                                        </ColBark>
                                                        <ColBark xs={6} sm={6} lg={6}>
                                                            <Titulo>Celular</Titulo>
                                                            <InputCinza type="text" value={this.state.conta.celular} onChange={e => this.preencheCampos(e, "celular")} />
                                                        </ColBark>
                                                    </RowBark>
                                                    <Titulo>E-Mail</Titulo>
                                                    <InputCinza type="text" value={this.state.conta.email} onChange={e => this.preencheCampos(e, "email")} />
                                                    <RowBark>
                                                        <ColBark xs={6} sm={6} lg={6}>
                                                            <Titulo>CEP</Titulo>
                                                            <InputCinza
                                                                type="text"
                                                                value={this.state.conta.cep}
                                                                onChange={e => this.preencheCampos(e, "cep")}
                                                                onButtonClick={this.getCEP}
                                                                icon="search"
                                                            />
                                                        </ColBark>
                                                    </RowBark>
                                                    <RowBark>
                                                        <ColBark xs={18} sm={18} lg={18}>
                                                            <Titulo>Endereço</Titulo>
                                                            <InputCinza type="text" value={this.state.conta.endereco} onChange={e => this.preencheCampos(e, "endereco")} />
                                                        </ColBark>
                                                        <ColBark xs={6} sm={6} lg={6}>
                                                            <Titulo>Número</Titulo>
                                                            <InputCinza type="text" value={this.state.conta.numero} onChange={e => this.preencheCampos(e, "numero")} />
                                                        </ColBark>
                                                    </RowBark>
                                                    <RowBark>
                                                        <ColBark xs={24} sm={24} lg={24}>
                                                            <Titulo>Bairro</Titulo>
                                                            <InputCinza type="text" value={this.state.conta.bairro} onChange={e => this.preencheCampos(e, "bairro")} />
                                                        </ColBark>
                                                    </RowBark>
                                                    <RowBark>
                                                        <ColBark xs={4} sm={4} lg={4}>
                                                            <Titulo>UF</Titulo>
                                                            <InputCinza type="text" value={this.state.conta.estado} onChange={e => this.preencheCampos(e, "estado")} />
                                                        </ColBark>
                                                        <ColBark xs={20} sm={20} lg={20}>
                                                            <Titulo>Cidade</Titulo>
                                                            <InputCinza type="text" value={this.state.conta.cidade} onChange={e => this.preencheCampos(e, "cidade")} />
                                                        </ColBark>
                                                    </RowBark>
                                                </FadingDiv>
                                            </ColBark>
                                        </RowBark>
                                        <RowBark>
                                            <ColBark lg={24}>
                                                <Titulo>Observação</Titulo>
                                                <InputCinza rows={8} type="textarea" value={this.state.conta.observacao} onChange={e => this.preencheCampos(e, "observacao")} />
                                            </ColBark>
                                        </RowBark>
                                        <RowBark>

                                            <ColBark xs={24}>
                                                <PanelButton>
                                                    <ButtonCircle onClick={this.atualizarConta} color="primary">Atualizar</ButtonCircle>
                                                </PanelButton>
                                            </ColBark>

                                        </RowBark>
                                    </CardBodyBark>
                                </CardBark>

                            </CardGroupBark>
                        </RowBark>
                    </ColBark>
                    <ColBark xs={24} sm={12} lg={6}></ColBark>
                </RowStyled>
            </ContainerTotal>
        )
    }
};
