import React, {Component} from 'react';
import { Container } from './styles';

export default class CardBark extends Component
{       
    render()
    {
        return (  
            <Container {...this.props}>
                {this.props.children}
            </Container>                                                     
        )
    }
};