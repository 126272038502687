import React, {Component} from 'react';
import ColorContext from '../../ColorContext';
import InputCinza from '../InputCinza/InputCinza';
import { Box, BoxTitle } from './styles';

export default class CardText extends Component
{
    static contextType = ColorContext;
    render()
    {
        const {colors} = this.context;
        return (           
            <Box>
                <BoxTitle primary={colors.primary}> {this.props.titulo}</BoxTitle>           
                <InputCinza style={{height:'100%',resize: 'none'}} 
                    radius={"bottom"}
                    type="textarea" 
                    maxlength="255"
                    rows="2"
                    value={this.props.value}
                    onChange={this.props.onChange}
                    placeholder={this.props.placeholder} />
       
            </Box>          
        )
    }
};