import React from "react";
import { RiEyeFill, RiEyeCloseLine, RiMailSendLine } from 'react-icons/ri';
import { MdAttachMoney, MdContentCopy, MdHistory, 
    MdOutlineAttachMoney,MdOutlineArrowForwardIos, MdOutlineArrowBackIos, MdAttachFile,
    MdOutlineCheckCircleOutline,MdOutlineCancel,MdOutlineSpeed,    
    MdPeopleOutline,MdOutlineEdit,MdOutlineColorLens, MdRadioButtonUnchecked,
    MdKeyboardArrowDown } from 'react-icons/md';
import { BiSearch, BiMap, BiWalletAlt, BiLogOut, BiSolidDownArrow } from 'react-icons/bi';
import { BsArrowDownCircle, BsCardList, BsFillCircleFill,
    BsCalculator, BsCreditCard, BsBriefcase,BsHandIndexThumb,
    BsCalendar3,BsTrash, BsTrashFill } from 'react-icons/bs';
import { FaWhatsapp, FaRegUserCircle, FaRegFilePowerpoint, FaUser, FaUserCircle,
    FaWpforms, FaRegTrashAlt, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { FiMail, FiPrinter,FiMap,FiSearch,FiCamera,FiLock,FiEdit } from "react-icons/fi";
import { IoSchoolOutline } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import { VscNotebook } from "react-icons/vsc";
import { TbFileCertificate } from "react-icons/tb";
import { ImDrawer2 } from "react-icons/im";
import { LuCastle } from "react-icons/lu";
import { CgLogOff } from "react-icons/cg";
import { HiPlus, HiOutlineCamera, HiOutlineDotsVertical } from 'react-icons/hi';
import { AiOutlineLock, AiFillStar, AiOutlineStar } from "react-icons/ai";
import { ImCheckboxUnchecked,ImCheckboxChecked } from 'react-icons/im';


const IconsBark = (props) => {     
    return (
        props.named === "RiEyeFill" ? <RiEyeFill {...props} /> :
        props.named === "RiEyeCloseLine" ? <RiEyeCloseLine {...props} /> :
        props.named === "MdAttachMoney" ? <MdAttachMoney {...props} /> :
        props.named === "MdContentCopy" ? <MdContentCopy {...props} /> :
        props.named === "MdHistory" ? <MdHistory {...props} /> :
        props.named === "MdOutlineAttachMoney" ? <MdOutlineAttachMoney {...props} /> :
        props.named === "MdOutlineArrowForwardIos" ? <MdOutlineArrowForwardIos {...props} /> :
        props.named === "BiSearch" ? <BiSearch {...props} /> :
        props.named === "BiMap" ? <BiMap {...props} /> :
        props.named === "BsArrowDownCircle" ? <BsArrowDownCircle {...props} /> :
        props.named === "BsCardList" ? <BsCardList {...props} /> :
        props.named === "RiMailSendLine" ? <RiMailSendLine {...props} /> :
        props.named === "FaWhatsapp" ? <FaWhatsapp {...props} /> :
        props.named === "FaRegUserCircle" ? <FaRegUserCircle {...props} /> :
        props.named === "FiMail" ? <FiMail {...props} /> :
        props.named === "FiPrinter" ? <FiPrinter {...props} /> :
        props.named === "MdAttachFile" ? <MdAttachFile {...props} /> :
        props.named === "IoSchoolOutline" ? <IoSchoolOutline {...props} /> :
        props.named === "FaRegFilePowerpoint" ? <FaRegFilePowerpoint {...props} /> :
        props.named === "IoIosArrowBack" ? <IoIosArrowBack {...props} /> :
        props.named === "MdOutlineCheckCircleOutline" ? <MdOutlineCheckCircleOutline {...props} /> :
        props.named === "MdRadioButtonUnchecked" ? <MdRadioButtonUnchecked {...props} /> :        
        props.named === "MdOutlineCancel" ? <MdOutlineCancel {...props} /> :
        props.named === "VscNotebook" ? <VscNotebook {...props} /> :    
        props.named === "TbFileCertificate" ? <TbFileCertificate {...props} /> :    
        props.named === "FiMap" ? <FiMap {...props} /> :  
        props.named === "MdOutlineSpeed" ? <MdOutlineSpeed {...props} /> :  
        props.named === "MdPeopleOutline" ? <MdPeopleOutline {...props} /> :  
        props.named === "ImDrawer2" ? <ImDrawer2 {...props} /> :  
        props.named === "BsCalculator" ? <BsCalculator {...props} /> :  
        props.named === "BsCreditCard" ? <BsCreditCard {...props} /> :  
        props.named === "BsBriefcase" ? <BsBriefcase {...props} /> :  
        props.named === "BsCalendar3" ? <BsCalendar3 {...props} /> :  
        props.named === "BiWalletAlt" ? <BiWalletAlt {...props} /> :  
        props.named === "LuCastle" ? <LuCastle {...props} /> :  
        props.named === "FaUser" ? <FaUser {...props} /> :  
        props.named === "CgLogOff" ? <CgLogOff {...props} /> :  
        props.named === "FiSearch" ? <FiSearch {...props} /> :  
        props.named === "MdOutlineEdit" ? <MdOutlineEdit {...props} /> :  
        props.named === "BsTrash" ? <BsTrash {...props} /> :  
        props.named === "BsFillCircleFill" ? <BsFillCircleFill {...props} /> :  
        props.named === "MdOutlineArrowBackIos" ? <MdOutlineArrowBackIos {...props} /> :  
        props.named === "BsHandIndexThumb" ? <BsHandIndexThumb {...props} /> :  
        props.named === "BiLogOut" ? <BiLogOut {...props} /> :  
        props.named === "MdOutlineColorLens" ? <MdOutlineColorLens {...props} /> :  
        props.named === "AiOutlineLock" ? <AiOutlineLock {...props} /> :  
        props.named === "BsTrashFill" ? <BsTrashFill {...props} /> :  
        props.named === "BiSolidDownArrow" ? <BiSolidDownArrow {...props} /> :  
        props.named === "FiCamera" ? <FiCamera {...props} /> :  
        props.named === "AiFillStar" ? <AiFillStar {...props} /> :  
        props.named === "AiOutlineStar" ? <AiOutlineStar {...props} /> :  
        props.named === "FaUserCircle" ? <FaUserCircle {...props} /> :          
        props.named === "MdKeyboardArrowDown" ? <MdKeyboardArrowDown {...props} /> :          
        props.named === "FiLock" ? <FiLock {...props} /> :          
        props.named === "FiEdit" ? <FiEdit {...props} /> :          
        props.named === "HiPlus" ? <HiPlus {...props} /> :    
        props.named === "FaWpforms" ? <FaWpforms {...props} /> :   
        props.named === "FaRegTrashAlt" ? <FaRegTrashAlt {...props} /> :   
        props.named === "FaRegEye" ? <FaRegEye {...props} /> :   
        props.named === "FaRegEyeSlash" ? <FaRegEyeSlash {...props} /> :           
        props.named === "HiOutlineCamera" ? <HiOutlineCamera {...props} /> :           
        props.named === "HiOutlineDotsVertical" ? <HiOutlineDotsVertical {...props} /> :           
        props.named === "ImCheckboxUnchecked" ? <ImCheckboxUnchecked {...props} /> :           
        props.named === "ImCheckboxChecked" ? <ImCheckboxChecked {...props} /> :                   
        


        null );
};

export default IconsBark;