import React, {Component} from 'react';
import FirebaseService from '../../service/FirebaseService';
import { ContainerTotal, DivFinish } from './styles';
import ColorContext from '../../ColorContext';
import imgLogo from '../../assets/img/brand/concluido_sucesso.png';

export default class MensagemFinal extends Component
{
    static contextType = ColorContext;

    constructor(props) {
        super(props);

        this.state = {            
            invalido: {status:false, message:''},          
            conta:{sexo:"M"}, 
            concluido:false,  
            loading:false,    
            slug:'',        
          };        
    }   

    componentDidMount(){
        const params =  window.location.hash.split("/");
        if ((params.length > 1) && (params[1] !== "")){
            const slug = params[1];
            this.setState({slug:slug});
            FirebaseService.getValidExists(slug,(result)=>{             
                localStorage.setItem("salao_id",result.id);  
                const {setColor} = this.context;
                setColor(result.cor_primaria,result.cor_secundaria);
                this.setState({primaria:result.cor_primaria,secundaria:result.cor_secundaria});
            },()=>{});
        }
    }

    render() {
        return (
            <ContainerTotal primaria={this.state.primaria} secundaria={this.state.secundaria}>             
                <DivFinish>
                    <img width="450px" height="350px" alt="imgLogo" src={imgLogo} />  
                    <p>Parabéns seus Dados foram registrados com Sucesso!</p>   
                </DivFinish> 
             </ContainerTotal>
        )
    }
};
