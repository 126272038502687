import styled from "styled-components";
import RowBark from "../Base/RowBark/RowBark";

export const RowStyled  = styled(RowBark)`
  margin-top: 20px;
  width: 98%;  
`

export const Titulo = styled.p`
  margin-bottom: 2px;
  margin-top:10px;
`

export const PanelButton  = styled.div`
  margin-top: 20px;
`

export const ContainerTotal = styled.div`
  background-image: ${props => "linear-gradient(to right, "+props.secundaria+","+props.primaria+")"};
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
`

export const BoxRollBack = styled.div`
  margin-bottom: 20px;
  margin-top: 60px;
`