import React, { Component } from 'react';
import ColorContext from '../../ColorContext';
import { ButtonStyled } from './styles';

export default class ButtonCircle extends Component {    
    static contextType = ColorContext;
    render() {
        const {colors} = this.context;
        return (
            <ButtonStyled 
                disabled={this.props.disabled}
                pad={this.props.pad}
                primary={colors.primary}
                secondary={colors.secondary}
                block={this.props.block} 
                color={this.props.color} 
                onClick={this.props.onClick}>
                {this.props.children}
            </ButtonStyled>  
        );
    }
}
