import styled from "styled-components";

export const ContainerTotal = styled.div`
  background-image: ${props => "linear-gradient(to right, "+props.secundaria+","+props.primaria+")"};
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;  
`

export const DivFinish = styled.div`
  background-color: white;
  height: max-content;
  position: absolute;
  left: 25%;
  top: 25%;
  padding: 15px;
  border-radius: 17px;
  width: 50%;
  display: flex;
  align-items: center;
`