import React, {Component} from 'react';
import { Col, } from 'rsuite';

export default class ColBark extends Component
{           
    render()
    {
        return (  
            <Col {...this.props}>
                {this.props.children}
            </Col>                                                     
        )
    }
};