import styled from "styled-components";
import RowBark from "../Base/RowBark/RowBark";
import CardBark from "../Base/CardBark/CardBark";

export const RowStyled  = styled(RowBark)`
  margin-top: 20px;
  width: 98%;  
`

export const Titulo = styled.p`
  margin-bottom: 2px;
  margin-top:10px;
`

export const PanelButton  = styled.div`
  margin-top: 20px;
`

export const ContainerTotal = styled.div`
  background-image: ${props => "linear-gradient(to right, "+props.secundaria+","+props.primaria+")"};
  display: flex;
  justify-content: center;
  height: 100vh;
  @media (max-width: 480px) {
    height: 100%;
  } 
`

export const CardCadastro = styled(CardBark)`

`