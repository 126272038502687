import styled from "styled-components";
import RowBark from "../Base/RowBark/RowBark";

export const RowStyled  = styled(RowBark)`
  margin-top: 15px;
  width: 98%;
`
export const RowOptions = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
`

export const DivPergunta = styled.div`
  display: ${props => props.visivel ? "block" : "none"};
`

export const Titulo = styled.p`
  margin-bottom: 2px;
`

export const ContainerTotal = styled.div`
  background-image: ${props => "linear-gradient(to right, "+props.secundaria+","+props.primaria+")"};
  display: flex;
  justify-content: center;
  height: 100vh;
  @media (max-width: 480px) {
    height: 100%;
  }
`