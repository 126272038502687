import React, {Component} from 'react';
import ColorContext from '../../ColorContext';
import BarColor from '../BarColor/BarColor';
import BoxImagePoint from '../BoxImagePoint/BoxImagePoint';
import { Box, BoxCardCenter, BoxTitle } from './styles';

const imageTopo = '/assets/img/escalas/salt_topo.png';
const imageAtras = '/assets/img/escalas/salt_atras.png';
const imageTopoVazio = '/assets/img/escalas/salt_topo_vazio.png';
const imageAtrasVazio = '/assets/img/escalas/salt_atras_vazio.png';

/// localhost:3000/analise/acometida/imagem/47287552/2/nao/3
/// localhost:3000/analise/acometida/imagem/47287552/2/sim/3

export default class CardBarColor extends Component
{
    static contextType = ColorContext;

    constructor(props) {
        super(props);
     
        this.getImagem = this.getImagem.bind(this);
        this.toSaveImage = this.toSaveImage.bind(this);
    } 

    toSaveImage(base64){
        if (this.props.Save){
            this.props.Save(base64);
        }
    }

    getImagem(){
        let retorno = window.location.origin;
        if (this.props.image==='topo-vazio'){
            retorno += imageTopoVazio        
        } else if (this.props.image==='atras-vazio') {
            retorno += imageAtrasVazio
         } else if (this.props.image==='topo'){
            retorno += imageTopo;
         } else {
            retorno += imageAtras;
         }
        return retorno;
    }

    render()
    {
        const {colors} = this.context;
        return (                   
            <Box>
                <BoxTitle primary={colors.primary}> {this.props.title}</BoxTitle>           
                <BoxCardCenter style={this.props.limpo ?  { display: 'none' } : {} }>                     
                    <BoxImagePoint 
                        Imagem={this.getImagem()} 
                        ImagemDefault={this.props.ImagemDefault}
                        Save={this.toSaveImage}
                    />
                </BoxCardCenter>
                <div style={{ padding:"20px" }}>
                    <BarColor
                    marks={this.props.marks} 
                    step={10}  
                    min={0} 
                    onChangeValue={this.props.onChangeValue}
                    value={this.props.value}/>
                </div>
       
            </Box>                              
        )
    }
};