import React, { Component } from 'react';
import QuadroFotos from '../../QuadroFotos/QuadroFotos';
import CardText from '../../CardText/CardText';
import TrackBar from '../../TrackBar/TrackBar';

export default class Etapa3 extends Component {
    render() {
        return (
            <div style={{ height: "100%", display: "grid", gap: "10px", gridTemplateColumns: "50% 50%" }}>
                <div style={{ margimBottom: '0%', display: "grid", gridTemplateRows: "50% 50%" }}>
           
                        <QuadroFotos
                            inactive={this.props.inactive}
                            onExcluir={this.props.onExcluir}
                            colunas={1}
                            passos={this.props.etapas}
                            data={this.props.couro_quadro}
                        />
       
       
                        <QuadroFotos
                            // onExcluir={this.toExcluir} 
                            inactive={this.props.inactive}
                            colunas={1}
                            passos={this.props.etapas}
                            data={
                                [
                                    {
                                        image: '../../assets/img/escalas/estrato-corneo.jpg',
                                        vazio: undefined,
                                        desenhada: '',
                                        chave: JSON.stringify({ etapa: 4, tipo: 2 }),
                                        titulo: 'Histologia do Couro Cabeludo',
                                    },
                                ]
                            }
                        />
                
                </div>
                <div style={{ height: "100%", display: "grid", gridTemplateRows: "auto auto auto 1fr" }}>
                    <div>
                        <TrackBar title='Oleosidade'
                            Change={this.props.ChangeCouroNota2}
                            value={this.props.GetCouroNota2(this.props.couro_nota2)}
                            max={100}
                            marks={this.props.marksOleosidade} />
                    </div>
                    <div>
                        <TrackBar title='Escamação'
                            Change={this.props.ChangeCouroNota3}
                            value={this.props.GetCouroNota3(this.props.couro_nota3)}
                            max={100}
                            marks={this.props.marksEscamacao} />
                    </div>
                    <div>
                        <TrackBar title='Inflamação'
                            Change={this.props.ChangeCouroNota4}
                            value={this.props.GetCouroNota4(this.props.couro_nota4)}
                            max={100}
                            marks={this.props.marksInflamacao} />
                    </div>
                    <div style={{ height: "100%" }}>
                        <CardText
                            placeholder={'Digite aqui...'}
                            height={'100%'}
                            value={this.props.couro_obs1}
                            onChange={this.props.toChangeTextObsv1}
                            titulo={'Parecer Médico'} />
                    </div>
                </div>
            </div>
        )
    }
};