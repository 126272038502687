import React, { Component } from 'react';
import FirebaseService from '../../service/FirebaseService';
import { Titulo, RowStyled, PanelButton, ContainerTotal, CardCadastro } from './styles';
import Ferramentas from '../../classes/ferramentas';
import Loading from '../Base/Loading/Loading';
import InputCinza from '../InputCinza/InputCinza';
import BoxAlert from '../BoxAlert/BoxAlert';
import ButtonCircle from '../ButtonCircle/ButtonCircle';
import ComboBoxCinza from '../ComboBoxCinza/ComboBoxCinza';
import ColorContext from '../../ColorContext';
import { RollBack } from '../Base/RollBack/RollBack';
import CardGroupBark from '../Base/CardGroupBark/CardGroupBark';
import CardBodyBark from '../Base/CardBodyBark/CardBodyBark';
import RowBark from '../Base/RowBark/RowBark';
import ColBark from '../Base/ColBark/ColBark';
import { FadingDiv } from '../Base/FadingDiv/styles';
import { TitleForm } from '../Base/TitleForm/styles';

export default class NovoCliente extends Component {
    static contextType = ColorContext;

    constructor(props) {
        super(props);

        this.state = {
            invalido: { status: false, message: '' },
            conta: { sexo: "M" },
            concluido: false,
            loading: false,
            slug: '',
        };

        this.criarConta = this.criarConta.bind(this);
        this.preencheCampos = this.preencheCampos.bind(this);
        this.paraVoltar = this.paraVoltar.bind(this);
        this.getCEP = this.getCEP.bind(this);
        this.getRowObs = this.getRowObs.bind(this);
    }

    getCEP() {
        this.setState({ loading: true });
        FirebaseService.GetCEP(this.state.conta.cep, (status, retorno) => {
            if (status.toString() === "0") {
                let newConta = this.state.conta;
                newConta.endereco = retorno.logradouro;
                newConta.bairro = retorno.bairro;
                newConta.cidade = retorno.cidade;
                newConta.estado = retorno.uf;
                this.setState({ conta: newConta, loading: false });
            } else {
                this.setState({ invalido: { status: true, message: "problemas para buscar cep" }, loading: false })
            }
        });
    }

    criarConta() {
        this.setState({ loading: true, invalido: { status: false, message: "" } });
        FirebaseService.NovoCliente(this.state.conta, (retorno) => {
            if (retorno.status.toString() === "0") {
                if (this.props.Externo) {
                    this.props.Proxima(retorno.retorno.id.toString());
                } else {
                    window.location.href = "#/" + Ferramentas.Slug() + "/EditarAnaminese/" + retorno.retorno.id.toString();
                }
            } else {
                this.setState({ invalido: { status: true, message: retorno.retorno }, loading: false })
            }
        });
    }

    preencheCampos(e, campo) {
        let newConta = this.state.conta;
        newConta[campo] = e.target.value;
        newConta["id_estabelecimento"] = localStorage.getItem("salao_id");
        this.setState({ conta: newConta });
    }

    paraVoltar(event) {
        event.preventDefault();
        window.location.href = (`#/${Ferramentas.Slug()}/lista`);
    }

    getRowObs() {
        return window.screen.height > 900 ? 8 : 4;
    }

    componentDidMount() {
        const params = window.location.hash.split("/");
        if ((params.length > 1) && (params[1] !== "")) {
            const slug = params[1];
            this.setState({ slug: slug });
            FirebaseService.getValidExists(slug, (result) => {
                localStorage.setItem("salao_id", result.id);
                const { setColor } = this.context;
                setColor(result.cor_primaria, result.cor_secundaria);
                this.setState({ primaria: result.cor_primaria, secundaria: result.cor_secundaria });
            }, () => { });
        }
    }

    render() {
        return (
            <ContainerTotal primaria={this.state.primaria} secundaria={this.state.secundaria}>
                <Loading active={this.state.loading} />
                <RowStyled>
                    <ColBark xs={24} sm={12} lg={6}></ColBark>
                    <ColBark xs={24} sm={12} lg={12}>
                        <RowBark>
                            <ColBark xs={24} sm={12} lg={12}>
                                {this.props.Externo ? null : <RollBack onClick={this.paraVoltar} />}
                                <br />
                            </ColBark>
                        </RowBark>
                        <RowBark>
                            <ColBark lg={24}>
                                <CardGroupBark>
                                    <CardCadastro>
                                        <CardBodyBark>
                                            <RowBark>
                                                <ColBark lg={24}>
                                                    <TitleForm>Cadastrar Paciente</TitleForm>
                                                    <RowBark>
                                                        <ColBark xs={24}>
                                                            <BoxAlert open={this.state.invalido.status} >{this.state.invalido.message}</BoxAlert>
                                                        </ColBark>
                                                    </RowBark>
                                                    <FadingDiv>
                                                        <Titulo>Nome Completo</Titulo>
                                                        <InputCinza type="text" onChange={e => this.preencheCampos(e, "nome")} />
                                                        <RowBark>
                                                            <ColBark xs={24} sm={6} lg={6}>
                                                                <Titulo>Nascimento</Titulo>
                                                                <InputCinza type="date" onChange={e => this.preencheCampos(e, "data_nasc")} />
                                                            </ColBark>
                                                            <ColBark xs={24} sm={6} lg={6}>
                                                                <Titulo>CPF</Titulo>
                                                                <InputCinza type="text" onChange={e => this.preencheCampos(e, "cpf")} />
                                                            </ColBark>
                                                            <ColBark xs={24} sm={6} lg={6}>
                                                                <Titulo>Sexo</Titulo>
                                                                <ComboBoxCinza
                                                                    onChange={e => this.preencheCampos(e, "sexo")}
                                                                    value={this.state.conta.sexo}
                                                                    list={[{ key: "M", value: "Masculino" }, { key: "F", value: "Feminino" }]}
                                                                >
                                                                </ComboBoxCinza>
                                                            </ColBark>
                                                            <ColBark xs={24} sm={6} lg={6}>
                                                                <Titulo>Celular</Titulo>
                                                                <InputCinza type="text" onChange={e => this.preencheCampos(e, "celular")} />
                                                            </ColBark>
                                                        </RowBark>
                                                        <Titulo>E-Mail</Titulo>
                                                        <InputCinza type="text" onChange={e => this.preencheCampos(e, "email")} />
                                                        <RowBark>
                                                            <ColBark xs={24} sm={6} lg={6}>
                                                                <Titulo>CEP</Titulo>
                                                                <InputCinza
                                                                    type="text"
                                                                    value={this.state.conta.cep}
                                                                    onChange={e => this.preencheCampos(e, "cep")}
                                                                    onButtonClick={this.getCEP}
                                                                    icon="search"
                                                                />
                                                            </ColBark>
                                                        </RowBark>
                                                        <RowBark>
                                                            <ColBark xs={24} sm="9" lg="9">
                                                                <Titulo>Endereço</Titulo>
                                                                <InputCinza type="text" value={this.state.conta.endereco} onChange={e => this.preencheCampos(e, "endereco")} />
                                                            </ColBark>
                                                            <ColBark xs={24} sm={6} lg={6}>
                                                                <Titulo>Número</Titulo>
                                                                <InputCinza type="text" value={this.state.conta.numero} onChange={e => this.preencheCampos(e, "numero")} />
                                                            </ColBark>
                                                        </RowBark>
                                                        <RowBark>
                                                            <ColBark xs={24} sm={10} lg={10}>
                                                                <Titulo>Bairro</Titulo>
                                                                <InputCinza type="text" value={this.state.conta.bairro} onChange={e => this.preencheCampos(e, "bairro")} />
                                                            </ColBark>

                                                            <ColBark xs={24} sm={4} lg={4}>
                                                                <Titulo>UF</Titulo>
                                                                <InputCinza type="text" value={this.state.conta.estado} onChange={e => this.preencheCampos(e, "estado")} />
                                                            </ColBark>
                                                            <ColBark xs={24} sm={10} lg={10}>
                                                                <Titulo>Cidade</Titulo>
                                                                <InputCinza type="text" value={this.state.conta.cidade} onChange={e => this.preencheCampos(e, "cidade")} />
                                                            </ColBark>
                                                        </RowBark>
                                                    </FadingDiv>
                                                </ColBark>
                                            </RowBark>
                                            <RowBark>
                                                <ColBark lg={24}>
                                                    <Titulo>Observação</Titulo>
                                                    <InputCinza rows={this.getRowObs()} type="textarea" onChange={e => this.preencheCampos(e, "observacao")} />
                                                </ColBark>
                                            </RowBark>
                                            <RowBark>
                                                <ColBark xs={24} style={{marginTop:"20px"}}>
                                                    <PanelButton>
                                                        <ButtonCircle onClick={this.criarConta} color="primary">Cadastrar</ButtonCircle>
                                                    </PanelButton>
                                                </ColBark>
                                            </RowBark>
                                        </CardBodyBark>
                                    </CardCadastro>
                                </CardGroupBark>
                            </ColBark>
                        </RowBark>
                    </ColBark>
                    <ColBark xs={24} sm={12} lg={6}></ColBark>
                </RowStyled>
            </ContainerTotal>
        )
    }
};
