import styled from "styled-components";

export const Box = styled.div` 
  height: 100%;
  padding: 0% 0% 0% 0%;
  border:2px solid #F5F5F5;
  border-radius: 20px;
  margin-bottom: 2%;
`

export const BoxTitle = styled.p`
  position: inherit;
  background-color: ${props => props.primary};
  color:white;
  margin: 0px;
  border-radius: 20px 20px 0px 0px;
  height: 30px;
  right: 0;
  left: 0;
  text-align: center;
  padding-top: 4px;
`
