import React, { Component } from 'react';
import './BarColor.css';
import { RowStyled, TextStyled } from './styles';
import ColBark from '../Base/ColBark/ColBark';
import RowBark from '../Base/RowBark/RowBark';
import { Grid } from 'rsuite';

class BarColor extends Component {
        constructor(props) {
        super(props);

        this.getListaLabels = this.getListaLabels.bind(this);
        this.getValue = this.getValue.bind(this);
        this.onClickValue = this.onClickValue.bind(this);        
    }

    onClickValue(val){
        if (this.props.onChangeValue){
            this.props.onChangeValue(val);
        }        
    }

    getValue(){
        let retorno = 0;
        const x = parseInt(this.props.value.toString());
        switch (true) {
            case (x < 30):
                retorno = 20;
                break;
            case (x < 45):
                retorno = 40;
                break;
            case (x < 80):
                retorno = 60;
                break;
            case (x < 90):
                retorno = 80;
                break;                                  
            default:
                retorno = 100;
                break;
        }
        return retorno.toString()+"%";
    }

    getListaLabels(){
        let retorno = [];
        let chaves = Object.keys(this.props.marks); 
        for(let i in chaves){    
            retorno.push(
                <ColBark key={retorno.length} xs={i==='2'?8:4} sm={i==='2'?8:4} lg={i==='2'?8:4} style={{padding:'0% 0% 0% 0%',textAlign: i==='0' ? 'left' : 'center'}}>
                    <TextStyled onClick={(e) => this.onClickValue(chaves[i] - 1)}>{this.props.marks[chaves[i]].label}</TextStyled>
                </ColBark>
            );
        }
        return retorno;
    }

  render() {
    return ( 
        <Grid fluid>   

            <RowBark>
                <ColBark xs={24} sm={24} lg={24}>
                    <div className="progress-bar">
                        <div className="progress-bar--gradient">
                            <div style={{width:this.getValue()}} className="progress-bar--progress">		
                            </div>
                            <div className="progress-bar--not-progress"></div>
                        </div>
                    </div>
                    <RowStyled>
                        {this.getListaLabels()}
                    </RowStyled>
             
                </ColBark>
            </RowBark>
        </Grid>
    );
  }
}

export default BarColor;
