import React, { Component } from 'react';
import { Box, BoxPontos, BoxTitle } from './styles';
import ColorContext from '../../ColorContext';
import IconsBark from '../../assets/icons/IconsBark';
import {  Dropdown } from 'rsuite';

export default class PanelEscala extends Component {
    static contextType = ColorContext;

    constructor(props) {
        super(props);

        this.state = {
            dropOpenEscala: false,
        };
    }

    clicked(escala) {
        if (this.props.onClick) {
            this.props.onClick(escala);
        }
        this.setState({ dropOpenEscala: !this.state.dropOpenEscala });
    }

    render() {
        const { colors } = this.context;
        return (
            <Box>
                <BoxTitle primary={colors.primary}> {this.props.titulo}
                    <BoxPontos>
                        <Dropdown title="leftStart" placement="leftStart"
                              renderTitle={children => {
                                return        <IconsBark named="HiOutlineDotsVertical" color='white' size={18} />;
                              }}
                        >                               
                                <Dropdown.Item panel style={{ padding: 10, width: 160, color:colors.primary }}>                
                                    <strong>Escala</strong>
                                </Dropdown.Item>
                                <Dropdown.Item divider />
                                <Dropdown.Item primary={colors.primary} onClick={(e) => this.clicked('norwood')} disabled={this.props.atual === 'norwood'} >{this.props.escalas_lista[0]}</Dropdown.Item>
                                <Dropdown.Item primary={colors.primary} onClick={(e) => this.clicked('salt')} disabled={this.props.atual === 'salt'} >{this.props.escalas_lista[1]}</Dropdown.Item>
                                <Dropdown.Item primary={colors.primary} onClick={(e) => this.clicked('olsen')} disabled={this.props.atual === 'olsen'} >{this.props.escalas_lista[2]}</Dropdown.Item>
                                <Dropdown.Item primary={colors.primary} last={true} onClick={(e) => this.clicked('ludwig')} disabled={this.props.atual === 'ludwig'} >{this.props.escalas_lista[3]}</Dropdown.Item>
                        </Dropdown>                        
                    </BoxPontos>
                </BoxTitle>
                {this.props.children}
            </Box>
        )
    }
};