import React, { Component } from 'react';
import './SliderGradiente.css';
import RowBark from '../Base/RowBark/RowBark';
import ColBark from '../Base/ColBark/ColBark';
import { Container } from './styles';

class SliderGradiente extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selecionados: this.props.start === undefined ? [] : this.props.start, 
      dropOpenEscala: false, 
    };

    this.handleChange = this.handleChange.bind(this);
    this.getListaLabels = this.getListaLabels.bind(this);
  }

  handleChange(event) {
    this.props.Change(event.target.value);
  }

  getListaLabels(){
    let retorno = [];
    let chaves = Object.keys(this.props.marks); 
    for(let i in chaves){
        retorno.push(
            <ColBark key={retorno.length} xs={i==='2'?8:4} sm={i==='2'?8:4} lg={i==='2'?8:4} style={{margin:'0% 0% 0% 0%', padding:'0%', textAlign: i==='0' ? 'left' : 'center'}}>
                <p style={{fontSize:'0.8em', marginBottom:'0px'}}>{this.props.marks[chaves[i]].label}</p>
            </ColBark>
        );
    }
    return retorno;
  }

  render() {
    return ( 
        <Container>   
            <RowBark>
                <ColBark xs={24} sm={24} lg={24}>
                    <input 
                        type="range" 
                        min={this.props.min} 
                        max={this.props.max} 
                        step={this.props.step}            
                        className="slider"
                        value={this.props.value} 
                        onChange={this.handleChange}
                        list="tickmarks"
                    /> 
                    <RowBark style={{padding:'0px', margin:'0px'}}>
                        {this.getListaLabels()}
                    </RowBark>
             
                </ColBark>
            </RowBark>
        </Container>
    );
  }
}

export default SliderGradiente;
