import React, {Component} from 'react';
import { BoxCortina, BoxLoading } from './styles';
import FirebaseService from '../../../service/FirebaseService';

export default class Loading extends Component
{
    render()
    {
        return (               
            <div>
                <BoxLoading active={this.props.active} server={FirebaseService.getHostServer()}/>          
                 <BoxCortina active={this.props.active}/>
            </div>
           
        )
    }
};