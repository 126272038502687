import React, { Component } from 'react';
import Steppers from '../Steppers/Steppers';
import FirebaseService from '../../service/FirebaseService';
import { ModalBark, ModalBodyBark, ModalFooterBark, ModalHeaderBark } from '../Base/ModalBark/ModalBark';
import Ferramentas from '../../classes/ferramentas';
import ColorContext from '../../ColorContext';
import { ContainerMsgCenter, ContainerTotal } from './styles';
import IconsBark from '../../assets/icons/IconsBark';
import Etapa1 from './Etapa1/Etapa1';
import Etapa2 from './Etapa2/Etapa2';
import Etapa5 from './Etapa5/Etapa5';
import Etapa3 from './Etapa3/Etapa3';
import Etapa4 from './Etapa4/Etapa4';
import CardBark from '../Base/CardBark/CardBark';
import CardBodyBark from '../Base/CardBodyBark/CardBodyBark';
import ButtonCircle from '../ButtonCircle/ButtonCircle';
import Loading from '../Base/Loading/Loading';

//const imgCamera = '../../assets/img/foto/icons8-camera-48.png';
let imgCamera = <IconsBark named="HiOutlineCamera" size={42} color={"purple"} />;

const marksOleosidade = {
    0: { style: { color: 'red', }, label: 'Extramamente \r Oleoso', },
    25: { style: { color: 'orange', }, label: 'Muito Oleoso', },
    50: { style: { color: 'yellow', }, label: 'Oleoso', },
    75: { style: { color: '#00cc66', }, label: ' Pouca  Oleosidade', },
    100: { style: { color: 'green', }, label: 'Não Possui  Oleosidade', },
};

const marksEscamacao = {
    0: { style: { color: 'red', }, label: 'Extramamente  Escamado', },
    25: { style: { color: 'orange', }, label: 'Muito  Escamado', },
    50: { style: { color: 'yellow', }, label: 'Escamado', },
    75: { style: { color: '#00cc66', }, label: 'Pouca  Escamação', },
    100: { style: { color: 'green', }, label: 'Sem  Escamação', },
};

const marksInflamacao = {
    0: { style: { color: 'red', }, label: 'Extramamente  Inflamado', },
    25: { style: { color: 'orange', }, label: 'Muito  Inflamado', },
    50: { style: { color: 'yellow', }, label: 'Inflamado', },
    75: { style: { color: '#00cc66', }, label: 'Pouca  Inflamado', },
    100: { style: { color: 'green', }, label: 'Sem  Inflamação', },
};

const marksEspessura = {
    30: { style: { color: 'red', }, label: 'Muito Fino', },
    45: { style: { color: 'orange', }, label: 'Fino', },
    80: { style: { color: 'yellow', }, label: 'Médio', },
    90: { style: { color: 'green', }, label: 'Grossos', },
    100: { style: { color: 'green', }, label: 'Muito Grossos', },
};

const marksDensidade = {
    30: { style: { color: 'red', }, label: 'Muito Baixa', },
    45: { style: { color: 'orange', }, label: 'Baixa', },
    80: { style: { color: 'yellow', }, label: 'Média', },
    90: { style: { color: 'green', }, label: 'Alta', },
    100: { style: { color: 'green', }, label: 'Muito Alta', },
};

const etapas = Ferramentas.getEtapas();

const escala_inicial_padrao = 'norwood';

const escalas_lista = ['NORWOOD - HAMILTON', 'SALT SCORE', 'OLSEN', 'LUDWIG'];

const opcoes_escalas = {
    norwood: [
        { label: 'I, II, II-A - 20%', value: 20 },
        { label: 'III, III-A, III-Vertex - 40%', value: 40 },
        { label: 'IV, IV-A, V - 60%', value: 60 },
        { label: 'V-A, VI, VII - 80%', value: 80 },
    ],
    olsen: [
        { label: 'Não se aplica - 100%', value: 100 },
        { label: 'Ilustração 1 - 75%', value: 75 },
        { label: 'Ilustração 2 - 50%', value: 50 },
        { label: 'Ilustração 3 - 25%', value: 25 },
    ],
    ludwig: [
        { label: 'I, I-2, I-3, I-4 - 20%', value: 20 },
        { label: 'II-1, II-2 - 40%', value: 40 },
        { label: 'III, Frontal - 60%', value: 60 },
        { label: 'Avançado - 80%', value: 80 },
    ],
    salt: [
        { label: 'Atrás - 24%', value: 24 },
        { label: 'Topo - 40%', value: 40 },
        { label: 'Lado Esquerdo - 18%', value: 18 },
        { label: 'Lado Direito - 18%', value: 18 },
    ]
};

export default class NovaAnalise extends Component {

    static contextType = ColorContext;
    intervalID = 0;

    constructor(props) {
        super(props);

        let { cliente, id } = this.props.match.params;

        this.state = {
            inactive: false,
            modal: false,
            cliente: cliente,
            id: id,
            datailCliente: { sexo: 'M' },
            refreshImage: '222',
            loading: false,
            andamento: 1,
            memoria_1: '',
            memoria_3: '',
            nome_cliente: '',
            analise_quadro: [],
            analise_obs: '',
            analise_nota: 0,
            densidade_quadro: [],
            densidade_fios_acometida: 0,
            densidade_fios_nao_acometida: 0,
            densidade_fios_acometida_custom: 0,
            densidade_fios_nao_acometida_custom: 0,
            densidade_obs1: '',
            densidade_obs2: '',
            densidade_nota3: 0,
            densidade_nota4: 0,
            densidade_acometida_64: '',
            densidade_nao_acometida_64: '',
            couro_quadro: [],
            couro_obs1: '',
            couro_nota1: 0,
            couro_nota2: 0,
            couro_nota3: 0,
            couro_nota4: 0,
            espessura_quadro: [],
            espessura_micro_acometida: 0,
            espessura_micro_nao_acometida: 0,
            espessura_micro_acometida_custom: 0,
            espessura_micro_nao_acometida_custom: 0,
            espessura_obs1: '',
            espessura_obs2: '',
            espessura_nota3: 0,
            espessura_nota4: 0,
            espessura_acometida_64: '',
            espessura_nao_acometida_64: '',
            dropOpenEscala: false,
            dropOpenNota1: false,
            escala: escala_inicial_padrao,
            escala_selecao: '',
            hash: '',
            aviso: { ativo: false, tipo: "danger", mensagem: '' },
        };

        this.toPrior = this.toPrior.bind(this);
        this.toNext = this.toNext.bind(this);
        this.onChangeTipo = this.onChangeTipo.bind(this);
        this.onChangeTitulo = this.onChangeTitulo.bind(this);
        this.onChangeTexto = this.onChangeTexto.bind(this);
        this.TodasEtapas = this.TodasEtapas.bind(this);
        this.toExcluir = this.toExcluir.bind(this);
        this.countDown = this.countDown.bind(this);
        this.getLinkMagnetico = this.getLinkMagnetico.bind(this);
        this.setEscala = this.setEscala.bind(this);
        this.toChangeTextObsv1 = this.toChangeTextObsv1.bind(this);
        this.toChangeTextObsv2 = this.toChangeTextObsv2.bind(this);
        this.getNomeEscala = this.getNomeEscala.bind(this);
        //this.setOpcoesEscala = this.setOpcoesEscala.bind(this);
        this.getOpcaoEscalaSelecionada = this.getOpcaoEscalaSelecionada.bind(this);
        this.AfterChangeDensidadeNota3 = this.AfterChangeDensidadeNota3.bind(this);
        this.AfterChangeDensidadeNota4 = this.AfterChangeDensidadeNota4.bind(this);
        this.ChangeCouroNota2 = this.ChangeCouroNota2.bind(this);
        this.ChangeCouroNota3 = this.ChangeCouroNota3.bind(this);
        this.ChangeCouroNota4 = this.ChangeCouroNota4.bind(this);
        this.ChangeEspessuraNota3 = this.ChangeEspessuraNota3.bind(this);
        this.ChangeEspessuraNota4 = this.ChangeEspessuraNota4.bind(this);
        this.selectOpcaoEscala = this.selectOpcaoEscala.bind(this);
        this.EtapaItem = this.EtapaItem.bind(this);
        this.PassoClick = this.PassoClick.bind(this);
        this.toSavePanel = this.toSavePanel.bind(this);
        //this.RevertValorMarcacao  = this.RevertValorMarcacao.bind(this);
        this.GetCouroNota2 = this.GetCouroNota2.bind(this);
        this.GetCouroNota3 = this.GetCouroNota3.bind(this);
        this.GetCouroNota4 = this.GetCouroNota4.bind(this);
        this.getObservacaoConclusao = this.getObservacaoConclusao.bind(this);
        this.DensidadeAcometida64 = this.DensidadeAcometida64.bind(this);
        this.DensidadeNaoAcometida64 = this.DensidadeNaoAcometida64.bind(this);
        this.EspessuraNaoAcometida64 = this.EspessuraNaoAcometida64.bind(this);
        this.EspessuraAcometida64 = this.EspessuraAcometida64.bind(this);
        this.ImagemDefaultAcometida = this.ImagemDefaultAcometida.bind(this);
        this.getFotoImage = this.getFotoImage.bind(this);
        this.EscalaClick = this.EscalaClick.bind(this);
        this.Finalizando = this.Finalizando.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toClose = this.toClose.bind(this);
        this.toYes = this.toYes.bind(this);
        this.goClient = this.goClient.bind(this);
        this.concatValues = this.concatValues.bind(this);
    }

    concatValues = (values) => {
        return values.map(item => item !== undefined && item !== null ? item.toString() : '').join('');
    }

    goClient = () => {
        window.location.href = (`#/${Ferramentas.Slug()}/lista/ItemCliente/${this.state.cliente}`);
    }

    Finalizando() {
        let obj = {
            "tipo": "completa",
            "responsavel": localStorage.getItem('email'),
            "status": "3",
            "cliente": this.state.cliente.toString(),
            "id": this.state.id.toString(),
        };
        FirebaseService.AnaliseAltera(obj, (data) => {
            this.goClient();
        });
    }

    toYes() {
        this.Finalizando();
    }

    toClose() {
        this.goClient();
    }

    toggle() {
        const modal = !this.state.modal;
        this.setState({ modal: modal });
    }

    EspessuraNaoAcometida64(base64) {
        if (this.state.inactive) {
            return;
        }
        if (base64 !== "") {
            this.setState({ espessura_nao_acometida_64: base64 });
        }
    }

    EspessuraAcometida64(base64) {
        if (this.state.inactive) {
            return;
        }
        if (base64 !== "") {
            this.setState({ espessura_acometida_64: base64 });
        }
    }

    DensidadeNaoAcometida64(base64) {
        if (this.state.inactive) {
            return;
        }
        if (base64 !== "") {
            this.setState({ densidade_nao_acometida_64: base64 });
        }
    }

    DensidadeAcometida64(base64) {
        if (this.state.inactive) {
            return;
        }
        if (base64 !== "") {
            this.setState({ densidade_acometida_64: base64 });
        }
    }

    ImagemDefaultAcometida(_etapa, _acometida) {
        return FirebaseService.getHostServer() + "/api/v2/analise/acometida/imagem/" + this.state.cliente + "/" + _etapa + "/" + _acometida + "/" + this.state.id + "?bx=" + this.state.refreshImage.toString();
    }

    getObservacaoConclusao(obs) {
        return (obs) && (obs !== '') ? obs : 'Sem comentários';
    }

    startTimer() {
        this.intervalID = setInterval(this.countDown, 10000);
    }

    countDown() {
        this.TodasEtapas();
    }

    onChangeTipo(e) {
        let novo = e.target.value;
        this.setState({ tipo: novo });
        console.log("onChangeTipo:" + novo);
    }

    onChangeTitulo(e) {
        let novo = e.target.value;
        this.setState({ titulo: novo });
        console.log("onChangeTitulo:" + novo);
    }

    onChangeTexto(e) {
        let novo = e.target.value;
        this.setState({ mensagem: novo });
        console.log("onChangeTexto:" + novo);
    }

    toSavePanel(afterSave) {
        this.setState({ loading: true });
        let pay = {
            cliente: this.state.cliente,
            tipo: 1,
            etapa: this.state.andamento,
            responsavel: localStorage.getItem('email'),
            atendimento_id: this.state.id,
        };
        if (this.state.andamento === 1) {
            pay.escala = this.state.escala;
            pay.escala_selecao = this.state.escala_selecao;
            pay.observacao1 = this.state.analise_obs.split('\'').join('');
            pay.nota1 = this.state.analise_nota;

            if (this.state.memoria_1 === this.concatValues([pay.escala, pay.escala_selecao, pay.observacao1, pay.nota1])) {
                afterSave();
                return;
            }

        } else if (this.state.andamento === 2) {
            pay.observacao1 = this.state.densidade_obs1.split('\'').join('');
            pay.observacao2 = this.state.densidade_obs2.split('\'').join('');
            pay.nota3 = this.state.densidade_nota3;
            pay.nota4 = this.state.densidade_nota4;
            pay.nota1_custom = this.state.densidade_fios_acometida_custom;
            pay.nota2_custom = this.state.densidade_fios_nao_acometida_custom;
            if (this.state.densidade_acometida_64 !== '') {
                pay.acometida64 = this.state.densidade_acometida_64;
            }
            if (this.state.densidade_nao_acometida_64 !== '') {
                pay.nao_acometida64 = this.state.densidade_nao_acometida_64;
            }
        } else if (this.state.andamento === 3) {
            pay.observacao1 = this.state.couro_obs1.split('\'').join('');
            pay.nota1 = this.state.couro_nota1;
            pay.nota2 = this.state.couro_nota2;
            pay.nota3 = this.state.couro_nota3;
            pay.nota4 = this.state.couro_nota4;

            if (this.state.memoria_3 === this.concatValues([pay.observacao1, pay.nota1, pay.nota2, pay.nota3, pay.nota4])) {
                afterSave();
                return;
            }

        } else if (this.state.andamento === 4) {
            pay.observacao1 = this.state.espessura_obs1.split('\'').join('');
            pay.observacao2 = this.state.espessura_obs2.split('\'').join('');
            pay.nota3 = this.state.espessura_nota3;
            pay.nota4 = this.state.espessura_nota4;
            pay.nota1_custom = this.state.espessura_micro_acometida_custom;
            pay.nota2_custom = this.state.espessura_micro_nao_acometida_custom;
            if (this.state.espessura_acometida_64 !== '') {
                pay.acometida64 = this.state.espessura_acometida_64;
            }
            if (this.state.espessura_nao_acometida_64 !== '') {
                pay.nao_acometida64 = this.state.espessura_nao_acometida_64;
            }
        } else {
            afterSave();
            return;
        }
        FirebaseService.AnaliseEtapa(pay, afterSave);
    }

    toNext(event) {
        event.preventDefault();
        if (this.state.inactive) {
            if (this.state.andamento === 5) {
                this.goClient();
            } else {
                let atual = this.state.andamento + 1;
                this.setState({ andamento: atual, loading: false, refreshImage: new Date().getMilliseconds().toString() });
            }
            return;
        }
        this.toSavePanel((data) => {
            if (this.state.andamento === 5) {
                this.setState({ loading: false });
                this.toggle();
            } else {
                let atual = this.state.andamento + 1;
                this.setState({ andamento: atual, loading: false, refreshImage: new Date().getMilliseconds().toString() });
            }
        });
    }

    toPrior(event) {
        event.preventDefault();
        let atual = this.state.andamento - 1;
        if (atual > 0) {
            this.setState({ andamento: atual, refreshImage: new Date().getMilliseconds().toString() });
        } else {
            window.location.href = (`#/${Ferramentas.Slug()}/lista/ItemCliente/${this.state.cliente}`);
            //window.location.reload(false);   
        }
    }

    setEscala(selecionado) {
        this.setState({ escala: selecionado, escala_selecao: '' });
    }

    toExcluir(chave) {
        console.log(chave);
        let obj = JSON.parse(chave);
        FirebaseService.ApagarTipoEtapa(this.state.id, obj.etapa, obj.tipo, (data) => {
            console.log(data);
            this.TodasEtapas();
        });
    }

    PassoClick(value) {
        let texto = value;
        if (texto.toUpperCase().indexOf('VOLTAR') > -1) {
            window.location.href = (`#/${Ferramentas.Slug()}/lista/ItemCliente/${this.state.cliente}`);
        } else {
            let i = 0;
            while (i !== etapas.length) {
                if (texto.toUpperCase().indexOf(etapas[i].label.toUpperCase()) > -1) {
                    this.setState({ andamento: i + 1 });
                    this.toSavePanel((data) => { this.setState({ loading: false, refreshImage: new Date().getMilliseconds().toString() }) });
                    break;
                }
                i++;
            }
        }
    }

    AfterChangeDensidadeNota3(value) {
        console.log(value);
        this.setState({ densidade_nota3: value });
    }

    AfterChangeDensidadeNota4(value) {
        console.log(value);
        this.setState({ densidade_nota4: value });
    }

    // RevertValorMarcacao(value,revert){
    //     let retorno = value == 40 ? 100 : value == 30 ? 75 : value == 20 ? 50 : value == 10 ? 25 : 0;
    //     if (revert){
    //         retorno = value == 100 ? 40 : value == 75  ? 30 : value == 50  ? 20 : value ==  25 ? 10  : 0;
    //     }
    //     return retorno;
    // }

    ChangeCouroNota2(value) {
        if (this.state.inactive) {
            return;
        }
        //value = this.RevertValorMarcacao(value,false);
        this.setState({
            couro_nota2: value,
            couro_nota1: Math.min(...[value, this.state.couro_nota3, this.state.couro_nota4])
        });
    }

    GetCouroNota2(value) {
        //return this.RevertValorMarcacao(value,true);
        return value;
    }

    ChangeCouroNota3(value) {
        if (this.state.inactive) {
            return;
        }
        //value = this.RevertValorMarcacao(value,false);
        this.setState({
            couro_nota3: value,
            couro_nota1: Math.min(...[value, this.state.couro_nota2, this.state.couro_nota4])
        });
    }

    GetCouroNota3(value) {
        return value; //this.RevertValorMarcacao(value,true);
    }

    ChangeCouroNota4(value) {
        if (this.state.inactive) {
            return;
        }
        //value = this.RevertValorMarcacao(value,false);
        this.setState({
            couro_nota4: value,
            couro_nota1: Math.min(...[value, this.state.couro_nota3, this.state.couro_nota2])
        });
    }

    GetCouroNota4(value) {
        return value; //this.RevertValorMarcacao(value,true);
    }

    ChangeEspessuraNota3(value) {
        if (this.state.inactive) {
            return;
        }
        this.setState({ espessura_nota3: value });
    }

    ChangeEspessuraNota4(value) {
        if (this.state.inactive) {
            return;
        }
        this.setState({ espessura_nota4: value });
    }

    toChangeTextObsv1(e) {
        if (this.state.inactive) {
            return;
        }
        if (this.state.andamento === 1) {
            this.setState({ analise_obs: e.target.value });
        } else if (this.state.andamento === 2) {
            this.setState({ densidade_obs1: e.target.value });
        } else if (this.state.andamento === 3) {
            this.setState({ couro_obs1: e.target.value });
        } else if (this.state.andamento === 4) {
            this.setState({ espessura_obs1: e.target.value });
        }
    }

    toChangeTextObsv2(e) {
        if (this.state.inactive) {
            return;
        }
        if (this.state.andamento === 2) {
            this.setState({ densidade_obs2: e.target.value });
        } else if (this.state.andamento === 4) {
            this.setState({ espessura_obs2: e.target.value });
        }
    }

    selectOpcaoEscala(idx, nota) {
        if (this.state.inactive) {
            return;
        }
        this.setState({ escala_selecao: idx, analise_nota: nota });
    }

    getLinkMagnetico(etapa, tipo) {
        return "leitorcameradino:etapa=" + etapa.toString() + ";tipo=" + tipo.toString() + ";cliente=" + this.state.cliente + ';nome=' + this.state.nome_cliente + ';analise=' + this.state.id + ';responsavel=' + localStorage.getItem('email');
    }

    getImagemEscala() {
        let retorno = '../../assets/img/escalas/salt score.png';
        if (this.state.escala === 'olsen') {
            retorno = '../../assets/img/escalas/escala olsen.jpg';
        } else if (this.state.escala === 'norwood') {
            retorno = '../../assets/img/escalas/escala-de-norwood.jpg';
        } else if (this.state.escala === 'ludwig') {
            retorno = '../../assets/img/escalas/ludwing.jpg';
        }
        return retorno;
    }

    getNomeEscala() {
        let retorno = escalas_lista[1];
        if (this.state.escala === 'olsen') {
            retorno = escalas_lista[2];
        } else if (this.state.escala === 'ludwig') {
            retorno = escalas_lista[3];
        } else if (this.state.escala === 'norwood') {
            retorno = escalas_lista[0];
        }
        return retorno;
    }

    // getOpcoesEscala() {
    //     let retorno = [];
    //     if (this.state.escala === 'olsen') {
    //         let item;
    //         for (item in opcoes_escalas.olsen) {
    //             let idx = item;
    //             retorno.push(
    //                 <DropdownItem onClick={(e) => this.setOpcoesEscala(idx)}
    //                     disabled={idx.toString() === this.state.analise_nota.toString() ? true : false} >{opcoes_escalas.olsen[idx].label}</DropdownItem>
    //             );
    //         }
    //     } else if (this.state.escala === 'norwood') {
    //         let item;
    //         for (item in opcoes_escalas.norwood) {
    //             let idx = item;
    //             retorno.push(
    //                 <DropdownItem onClick={(e) => this.setOpcoesEscala(idx)}
    //                     disabled={idx.toString() === this.state.analise_nota.toString() ? true : false} >{opcoes_escalas.norwood[idx].label}</DropdownItem>
    //             );
    //         }
    //     } else if (this.state.escala === 'ludwig') {
    //         let item;
    //         for (item in opcoes_escalas.ludwig) {
    //             let idx = item;
    //             retorno.push(
    //                 <DropdownItem onClick={(e) => this.setOpcoesEscala(idx)}
    //                     disabled={idx.toString() === this.state.analise_nota.toString() ? true : false} >{opcoes_escalas.ludwig[idx].label}</DropdownItem>
    //             );
    //         }
    //     } else if (this.state.escala === 'salt') {
    //         let item;
    //         for (item in opcoes_escalas.salt) {
    //             let idx = item;
    //             retorno.push(
    //                 <DropdownItem onClick={(e) => this.setOpcoesEscala(idx)}
    //                     disabled={idx.toString() === this.state.analise_nota.toString() ? true : false} >{opcoes_escalas.salt[idx].label}</DropdownItem>
    //             );
    //         }
    //     }
    //     return retorno;
    // }

    // setOpcoesEscala(selecionada) {
    //     this.setState({ analise_nota: selecionada });
    // }

    getOpcaoEscalaSelecionada() {
        let retorno = '';
        if (this.state.escala === 'olsen') {
            retorno = opcoes_escalas.olsen[this.state.analise_nota].label;
        } else if (this.state.escala === 'norwood') {
            retorno = opcoes_escalas.norwood[this.state.analise_nota].label;
        } else if (this.state.escala === 'ludwig') {
            retorno = opcoes_escalas.ludwig[this.state.analise_nota].label;
        } else if (this.state.escala === 'salt') {
            retorno = opcoes_escalas.salt[this.state.analise_nota].label;
        }
        return retorno;
    }

    getFotoImage(_image) {
        let retorno = _image;
        if (_image != null) {
            retorno += '?' + global.Date.now();
        }
        return retorno;
    }

    EtapaItem(item, etapa, tipo) {
        return {
            image: this.getFotoImage(FirebaseService.getHostServer() + item['foto' + tipo.toString()]),
            desenhada: item['foto_desenhada' + tipo.toString()] ? this.getFotoImage(FirebaseService.getHostServer() + item['foto_desenhada' + tipo.toString()]) : null,
            vazio: false,
            titulo: item.etapa_nome + " > " + item['foto_nome' + tipo.toString()],
            chave: JSON.stringify({ etapa: etapa, tipo: tipo }),
            nota: item['nota' + tipo.toString()],
        };
    }

    TodasEtapas() {
        FirebaseService.TodasEtapas(this.state.cliente, this.state.id, (data, hash) => {
            if (this.state.hash !== hash) {
                let escala_novo = this.state.datailCliente && this.state.datailCliente.sexo && this.state.datailCliente.sexo === "F" ? "ludwig" : escala_inicial_padrao;
                let escala_selecao_novo = '0';
                let analise_obs_novo = '';
                let couro_obs1_novo = '';
                let densidade_obs1_novo = '';
                let densidade_obs2_novo = '';
                let espessura_obs1_novo = '';
                let espessura_obs2_novo = '';
                let analise_nota_novo = 0;
                let densidade_nota3_novo = 0;
                let densidade_nota4_novo = 0;
                let couro_nota1_novo = 0;
                let couro_nota2_novo = 0;
                let couro_nota3_novo = 0;
                let couro_nota4_novo = 0;
                let espessura_nota3_novo = 0;
                let espessura_nota4_novo = 0;

                let analise_quadros_novo = [
                    {
                        image: imgCamera,
                        vazio: this.getLinkMagnetico(1, 1),
                        titulo: 'Superior',
                    },
                    {
                        image: imgCamera,
                        vazio: this.getLinkMagnetico(1, 2),
                        titulo: 'Costas',
                    },
                    {
                        image: imgCamera,
                        vazio: this.getLinkMagnetico(1, 3),
                        titulo: 'Lateral Esquerda',
                    },
                    {
                        image: imgCamera,
                        vazio: this.getLinkMagnetico(1, 4),
                        titulo: 'Lateral Direita',
                    },
                ];
                let densidade_quadros_novo = [
                    {
                        image: imgCamera,
                        vazio: this.getLinkMagnetico(2, 1),
                        titulo: 'Área Acometida',
                    },
                    {
                        image: imgCamera,
                        vazio: this.getLinkMagnetico(2, 2),
                        titulo: 'Área Não Acometida',
                    },
                ];
                let densidade_fios_acometida_custom_novo = 0;
                let densidade_fios_nao_acometida_custom_novo = 0;
                let densidade_fios_acometida_novo = 0;
                let densidade_fios_nao_acometida_novo = 0;
                let couro_quadros_novo = [
                    {
                        image: imgCamera,
                        vazio: this.getLinkMagnetico(3, 1),
                        titulo: 'Paciente',
                    },
                ];
                let espessura_quadros_novo = [
                    {
                        image: imgCamera,
                        vazio: this.getLinkMagnetico(4, 1),
                        titulo: 'Área Acometida',
                    },
                    {
                        image: imgCamera,
                        vazio: this.getLinkMagnetico(4, 2),
                        titulo: 'Área Não Acometida',
                    },
                ];
                let espessura_micro_acometida_novo = 0;
                let espessura_micro_nao_acometida_novo = 0;
                let espessura_micro_acometida_custom_novo = 0;
                let espessura_micro_nao_acometida_custom_novo = 0;
                for (const key in data) {
                    if (data[key].etapa.toString() === "1") {
                        escala_novo = data[key].escala;
                        escala_selecao_novo = data[key].escala_selecao;
                        analise_nota_novo = data[key].nota1;
                        analise_obs_novo = data[key].observacao1;
                        if (data[key].foto1 != null) {
                            analise_quadros_novo[0] = this.EtapaItem(data[key], 1, 1);
                        }
                        if (data[key].foto2 != null) {
                            analise_quadros_novo[1] = this.EtapaItem(data[key], 1, 2);
                        }
                        if (data[key].foto3 != null) {
                            analise_quadros_novo[2] = this.EtapaItem(data[key], 1, 3);
                        }
                        if (data[key].foto4 != null) {
                            analise_quadros_novo[3] = this.EtapaItem(data[key], 1, 4);
                        }
                    } else if (data[key].etapa.toString() === "2") {
                        densidade_fios_acometida_novo = data[key].nota1;
                        densidade_fios_nao_acometida_novo = data[key].nota2;
                        densidade_obs1_novo = data[key].observacao1;
                        densidade_obs2_novo = data[key].observacao2;
                        densidade_nota3_novo = data[key].nota3;
                        densidade_nota4_novo = data[key].nota4;
                        densidade_fios_acometida_custom_novo = ((data[key].nota1_custom === null) || (data[key].nota1_custom === 0)) ? data[key].nota1 : data[key].nota1_custom;
                        densidade_fios_nao_acometida_custom_novo = ((data[key].nota2_custom === null) || (data[key].nota2_custom === 0)) ? data[key].nota2 : data[key].nota2_custom;
                        if (data[key].foto1 != null) {
                            densidade_quadros_novo[0] = this.EtapaItem(data[key], 2, 1);
                        }
                        if (data[key].foto2 != null) {
                            densidade_quadros_novo[1] = this.EtapaItem(data[key], 2, 2);
                        };
                    } else if (data[key].etapa.toString() === "3") {
                        if (data[key].foto1 != null) {
                            couro_quadros_novo[0] = this.EtapaItem(data[key], 3, 1);
                        }
                        couro_obs1_novo = data[key].observacao1;
                        couro_nota1_novo = data[key].nota1;
                        couro_nota2_novo = data[key].nota2;
                        couro_nota3_novo = data[key].nota3;
                        couro_nota4_novo = data[key].nota4;
                    } else if (data[key].etapa.toString() === "4") {
                        if (data[key].foto1 != null) {
                            espessura_quadros_novo[0] = this.EtapaItem(data[key], 4, 1);
                        }
                        espessura_micro_acometida_novo = data[key].nota1;
                        if (data[key].foto2 != null) {
                            espessura_quadros_novo[1] = this.EtapaItem(data[key], 4, 2);
                            // espessura_quadros_novo[1] = {
                            //     image:FirebaseService.getHostServer()+data[key].foto2,
                            //     desenhada:data[key].foto_desenhada2!=null?FirebaseService.getHostServer()+data[key].foto_desenhada2:null,
                            //     vazio:false,
                            //     titulo:data[key].etapa_nome+" > "+data[key].foto_nome2,
                            //     chave: JSON.stringify({etapa:4,tipo:2}) ,
                            // };
                            espessura_micro_nao_acometida_novo = data[key].nota2;
                        }
                        espessura_micro_acometida_custom_novo = ((data[key].nota1_custom === null) || (data[key].nota1_custom === 0)) ? data[key].nota1 : data[key].nota1_custom;
                        espessura_micro_nao_acometida_custom_novo = ((data[key].nota2_custom === null) || (data[key].nota2_custom === 0)) ? data[key].nota2 : data[key].nota2_custom;

                        espessura_obs1_novo = data[key].observacao1;
                        espessura_obs2_novo = data[key].observacao2;
                        espessura_nota3_novo = data[key].nota3;
                        espessura_nota4_novo = data[key].nota4;
                    }
                }
                this.setState({
                    hash: hash,
                    densidade_fios_acometida_custom: densidade_fios_acometida_custom_novo,
                    densidade_fios_nao_acometida_custom: densidade_fios_nao_acometida_custom_novo,
                    analise_quadro: analise_quadros_novo,
                    densidade_quadro: densidade_quadros_novo,
                    densidade_fios_acometida: densidade_fios_acometida_novo,
                    densidade_fios_nao_acometida: densidade_fios_nao_acometida_novo,
                    couro_quadro: couro_quadros_novo,
                    espessura_quadro: espessura_quadros_novo,
                    espessura_micro_acometida: espessura_micro_acometida_novo,
                    espessura_micro_nao_acometida: espessura_micro_nao_acometida_novo,
                    espessura_micro_acometida_custom: espessura_micro_acometida_custom_novo,
                    espessura_micro_nao_acometida_custom: espessura_micro_nao_acometida_custom_novo,
                    escala: escala_novo,
                    escala_selecao: escala_selecao_novo,
                    analise_obs: analise_obs_novo,
                    couro_obs1: couro_obs1_novo,
                    densidade_obs1: densidade_obs1_novo,
                    densidade_obs2: densidade_obs2_novo,
                    espessura_obs1: espessura_obs1_novo,
                    espessura_obs2: espessura_obs2_novo,
                    analise_nota: analise_nota_novo,
                    densidade_nota3: densidade_nota3_novo,
                    densidade_nota4: densidade_nota4_novo,
                    couro_nota1: couro_nota1_novo,
                    couro_nota2: couro_nota2_novo,
                    couro_nota3: couro_nota3_novo,
                    couro_nota4: couro_nota4_novo,
                    espessura_nota3: espessura_nota3_novo,
                    espessura_nota4: espessura_nota4_novo,
                    memoria_1: this.concatValues([escala_novo, escala_selecao_novo, analise_obs_novo, analise_nota_novo]),
                    memoria_3: this.concatValues([couro_obs1_novo, couro_nota1_novo, couro_nota2_novo, couro_nota3_novo, couro_nota4_novo]),
                });
            }
        });
    }

    EscalaClick(escala) {
        if (this.state.inactive) {
            return;
        }
        this.setEscala(escala);
    }

    componentDidMount() {
        let tk = localStorage.getItem('token');
        if (tk) {
            FirebaseService.getValidAutentic(Ferramentas.Slug(), (data) => {

            }, (erro) => {
                this.props.history.push('/' + Ferramentas.Slug())
            });
        }
        else {
            this.props.history.push('/' + Ferramentas.Slug())
        }

        const params = window.location.hash.split("/");
        if ((params.length > 1) && (params[1] !== "")) {
            const slug = params[1];
            this.setState({ slug: slug });
            FirebaseService.AnaliseUnico(this.state.cliente, this.state.id, (data) => {
                if (data.length > 0) {
                    this.setState({ nome_cliente: data[0].nome_cliente, inactive: data[0].status.toString() === "3" });
                }
            });
            FirebaseService.getValidExists(slug, (result) => {
                localStorage.setItem("salao_id", result.id);
                const { setColor } = this.context;
                setColor(result.cor_primaria, result.cor_secundaria);
                this.setState({ primaria: result.cor_primaria, secundaria: result.cor_secundaria });
            }, () => { });
            FirebaseService.getDataCliente(this.state.cliente, (dataReceived) => {
                if (dataReceived) {
                    this.setState({ datailCliente: dataReceived }, () => {
                        this.TodasEtapas();
                        this.startTimer();
                    });
                } else {
                    const msg = JSON.stringify({ message: "Cliente não existe!", title: "Error", type: "error" });
                    sessionStorage.setItem('msg', msg);
                    window.location.href = ("#/" + Ferramentas.Slug() + "/lista");
                }
            });
        };
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    render() {
        const { colors } = this.context;
        imgCamera = <IconsBark named="HiOutlineCamera" size={42} color={colors.primary} />;

        return (
            <ContainerTotal primaria={this.state.primaria} secundaria={this.state.secundaria} monitor={Ferramentas.getMonitor()}>
                <ModalBark isOpen={this.state.modal} toggle={this.toClose}
                    fade={true}>
                    <ModalHeaderBark toggle={this.toClose}>Confirmação</ModalHeaderBark>
                    <ModalBodyBark>{"Confirma a Finalização desta analise?"}</ModalBodyBark>
                    <ModalFooterBark style={{ display: "flex" }}>
                        <ButtonCircle color="primary" onClick={this.toYes}>Sim</ButtonCircle>{' '}
                        <ButtonCircle color="secondary" onClick={this.toClose}>Não</ButtonCircle>
                    </ModalFooterBark>
                </ModalBark>
                <Loading active={this.state.loading} />
                <div style={{ display: "grid", gridTemplateRows: "100px 1fr" }}>
                    <div>
                        <Steppers
                            key={Math.random()}
                            steps={etapas}
                            activeStep={this.state.andamento - 1}
                            onClick={this.PassoClick}
                            onVoltar={this.toPrior}
                            onAvancar={this.toNext}
                        />
                    </div>
                    <div>
                        <ContainerMsgCenter>
                            {this.state.inactive ? "FINALIZADO" : null}
                        </ContainerMsgCenter>
                        <CardBark style={{ marginBottom: '0%', borderRadius: "20px", height: "calc( 100vh - 150px )" }}>
                            <CardBodyBark>

                                {this.state.andamento === 1 ?
                                    <Etapa1
                                        inactive={this.state.inactive}
                                        etapas={etapas}
                                        analise_quadro={this.state.analise_quadro}
                                        escalas_lista={escalas_lista}
                                        escala={this.state.escala}
                                        escala_selecao={this.state.escala_selecao}
                                        analise_obs={this.state.analise_obs}
                                        getNomeEscala={this.getNomeEscala}

                                        toExcluir={this.toExcluir}
                                        EscalaClick={this.EscalaClick}
                                        selectOpcaoEscala={this.selectOpcaoEscala}
                                        toChangeTextObsv1={this.toChangeTextObsv1}
                                    /> :
                                    this.state.andamento === 2 ?
                                        <Etapa2
                                            inactive={this.state.inactive}
                                            etapas={etapas}
                                            analise_quadro={this.state.analise_quadro}
                                            escalas_lista={escalas_lista}
                                            escala={this.state.escala}
                                            escala_selecao={this.state.escala_selecao}
                                            analise_obs={this.state.analise_obs}
                                            getNomeEscala={this.getNomeEscala}
                                            densidade_quadro={this.state.densidade_quadro}
                                            densidade_fios_acometida={this.state.densidade_fios_acometida}
                                            densidade_fios_acometida_custom={this.state.densidade_fios_acometida_custom}
                                            marksDensidade={marksDensidade}
                                            densidade_fios_nao_acometida={this.state.densidade_fios_nao_acometida}
                                            densidade_fios_nao_acometida_custom={this.state.densidade_fios_nao_acometida_custom}
                                            densidade_obs2={this.state.densidade_obs2}
                                            densidade_obs1={this.state.densidade_obs1}

                                            toExcluir={this.toExcluir}
                                            EscalaClick={this.EscalaClick}
                                            selectOpcaoEscala={this.selectOpcaoEscala}
                                            toChangeTextObsv1={this.toChangeTextObsv1}
                                            DensidadeAcometida64={this.DensidadeAcometida64}
                                            DensidadeNaoAcometida64={this.DensidadeNaoAcometida64}
                                            ImagemDefaultAcometida={this.ImagemDefaultAcometida}
                                            toChangeTextObsv2={this.toChangeTextObsv2}

                                            onDensidade_fios_nao_acometida_custom={(v) => this.setState({ densidade_fios_nao_acometida_custom: v })}
                                            onDensidade_fios_acometida_custom={(v) => this.setState({ densidade_fios_acometida_custom: v })}

                                        /> :
                                        this.state.andamento === 3 ?
                                            <Etapa3
                                                andamento={this.state.andamento}
                                                inactive={this.state.inactive}
                                                onExcluir={this.toExcluir}
                                                etapas={etapas}
                                                couro_quadro={this.state.couro_quadro}
                                                ChangeCouroNota2={this.ChangeCouroNota2}
                                                GetCouroNota2={this.GetCouroNota2}
                                                couro_nota2={this.state.couro_nota2}
                                                marksOleosidade={marksOleosidade}
                                                ChangeCouroNota3={this.ChangeCouroNota3}
                                                GetCouroNota3={this.GetCouroNota3}
                                                couro_nota3={this.state.couro_nota3}
                                                marksEscamacao={marksEscamacao}
                                                ChangeCouroNota4={this.ChangeCouroNota4}
                                                GetCouroNota4={this.GetCouroNota4}
                                                couro_nota4={this.state.couro_nota4}
                                                marksInflamacao={marksInflamacao}
                                                couro_obs1={this.state.couro_obs1}
                                                toChangeTextObsv1={this.toChangeTextObsv1}
                                            /> :
                                            this.state.andamento === 4 ?
                                                <Etapa4
                                                    andamento={this.state.andamento}
                                                    inactive={this.state.inactive}
                                                    onExcluir={this.toExcluir}
                                                    etapas={etapas}
                                                    espessura_quadro={this.state.espessura_quadro}
                                                    espessura_micro_acometida={this.state.espessura_micro_acometida}
                                                    ImagemDefaultAcometida={this.ImagemDefaultAcometida}
                                                    espessura_micro_acometida_custom={this.state.espessura_micro_acometida_custom}
                                                    EspessuraAcometida64={this.EspessuraAcometida64}
                                                    marksEspessura={marksEspessura}
                                                    espessura_obs1={this.state.espessura_obs1}
                                                    toChangeTextObsv1={this.toChangeTextObsv1}
                                                    espessura_micro_nao_acometida={this.state.espessura_micro_nao_acometida}
                                                    EspessuraNaoAcometida64={this.EspessuraNaoAcometida64}
                                                    espessura_micro_nao_acometida_custom={this.state.espessura_micro_nao_acometida_custom}
                                                    espessura_obs2={this.state.espessura_obs2}
                                                    toChangeTextObsv2={this.toChangeTextObsv2}

                                                    onEspessura_micro_nao_acometida_custom={v => this.setState({ espessura_micro_nao_acometida_custom: v })}
                                                    onEspessura_micro_acometida_custom={v => this.setState({ espessura_micro_acometida_custom: v })}
                                                /> :
                                                this.state.andamento === 5 ?
                                                    <Etapa5
                                                        inactive={this.state.inactive}
                                                        etapas={etapas}
                                                        analise_quadro={this.state.analise_quadro}
                                                        escalas_lista={escalas_lista}
                                                        escala={this.state.escala}
                                                        escala_selecao={this.state.escala_selecao}
                                                        analise_obs={this.state.analise_obs}
                                                        getNomeEscala={this.getNomeEscala}
                                                        analise_nota={this.state.analise_nota}
                                                        marksEspessura={marksEspessura}

                                                        densidade_fios_acometida_custom={this.state.densidade_fios_acometida_custom}
                                                        couro_nota2={this.state.couro_nota2}
                                                        couro_nota3={this.state.couro_nota3}
                                                        couro_nota4={this.state.couro_nota4}
                                                        espessura_micro_acometida_custom={this.state.espessura_micro_acometida_custom}
                                                        espessura_micro_nao_acometida_custom={this.state.espessura_micro_nao_acometida_custom}
                                                        densidade_fios_nao_acometida_custom={this.state.densidade_fios_nao_acometida_custom}
                                                        densidade_obs1={this.state.densidade_obs1}
                                                        couro_obs1={this.state.couro_obs1}
                                                        espessura_obs1={this.state.espessura_obs1}

                                                        toExcluir={this.toExcluir}
                                                        EscalaClick={this.EscalaClick}
                                                        selectOpcaoEscala={this.selectOpcaoEscala}
                                                        toChangeTextObsv1={this.toChangeTextObsv1}

                                                        getObservacaoConclusao={v => this.getObservacaoConclusao(v)}
                                                    /> :
                                                    null
                                }
                            </CardBodyBark>
                        </CardBark>
                    </div>
                </div>

            </ContainerTotal>
        );
    }
}
