import React, {Component} from 'react';
import { Row } from 'rsuite';

export default class RowBark extends Component
{       
    render()
    {
        return (  
            <Row className="show-grid" {...this.props}>
                {this.props.children}
            </Row>                                                     
        )
    }
};