import styled from "styled-components";
import ColBark from '../Base/ColBark/ColBark';
import RowBark from '../Base/RowBark/RowBark';

export const ColStyled  = styled(ColBark)`
    height:25px;
    font-size:1em;
    padding:0% 2% 2% 2%; 
`
export const TextStyled  = styled.p`
  font-size: 0.7em;
  margin-Bottom: 0px;
  cursor: pointer;

  :hover {
    color: #20a8d8;
  }
`

export const RowStyled  = styled(RowBark)`
  padding:0px;
  margin:0px;
`