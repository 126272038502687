import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { BoxAvancar, BoxCircle, BoxCircleLine, BoxContainerCircle, BoxLine, BoxStepper, BoxText, BoxVoltar, Container } from './styles';
import IconsBark from '../../assets/icons/IconsBark';
import Ferramentas from '../../classes/ferramentas';
export default class Steppers extends Component {
    constructor(props) {
        super(props);

        this.montagem = this.montagem.bind(this);
        this.click = this.click.bind(this);
        this.avancar = this.avancar.bind(this);
        this.voltar = this.voltar.bind(this);
    }

    click(e) {
        e.preventDefault();
        if (this.props.onClick) {
            let title = e.target.innerText;
            this.props.onClick(title);
        }
    }

    avancar(e) {
        e.preventDefault();
        if (this.props.onAvancar) {
            this.props.onAvancar(e);
        }
    }

    voltar(e) {
        e.preventDefault();
        if (this.props.onVoltar) {
            this.props.onVoltar(e);
        }
    }

    montagem() {
        let passed = false;
        let retorno = [];
        if (Ferramentas.getMonitor()) {
            for (const key in this.props.steps) {
                let selected = (parseInt(this.props.activeStep.toString()) === parseInt(key.toString()));
                if (passed === false) {
                    passed = selected;
                }
                retorno.push(
                    <BoxStepper key={retorno.length}>
                        <BoxCircleLine>
                            <BoxLine invisible={key === "0"} future={passed && selected === false}></BoxLine>
                            <BoxContainerCircle><BoxCircle selected={selected} future={passed && selected === false}></BoxCircle></BoxContainerCircle>
                            <BoxLine invisible={key === (this.props.steps.length - 1).toString()} selected={selected} future={passed && selected === false}></BoxLine>
                        </BoxCircleLine>
                        <BoxText onClick={this.click} selected={selected} future={passed && selected === false}>
                            {this.props.steps[key].label.toString().toUpperCase()}
                        </BoxText>
                    </BoxStepper>

                );
            }
        } else {
            retorno.push(
                <BoxText onClick={this.click} selected={true} future={false} style={{display:"flex", justifyContent:"center", marginBottom: "70px"}}>
                {this.props.steps[this.props.activeStep].label.toString().toUpperCase()}
                </BoxText>
            )
        }
        return retorno;
    }

    render() {
        return (   
            <Container>
                <BoxVoltar mobile={!Ferramentas.getMonitor()} ><Link onClick={this.voltar} style={{ textDecoration: 'none', color: "white" }}><IconsBark size={18} named={"MdOutlineArrowBackIos"} />&nbsp;Voltar</Link></BoxVoltar>
                {this.montagem()}
                <BoxAvancar mobile={!Ferramentas.getMonitor()}><Link onClick={this.avancar} style={{ textDecoration: 'none', color: "white" }}>Avançar&nbsp;<IconsBark size={18} named={"MdOutlineArrowForwardIos"} /></Link></BoxAvancar>
            </Container>          
        )
    }
};