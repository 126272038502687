import 'rsuite/dist/styles/rsuite-default.css';
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import IconsBark from '../../../assets/icons/IconsBark';

const styleLink = {textDecoration:'none', color:"white", fontSize:"1em", display:"flex", alignItems:"center",margin:"0",cursor:"pointer"};

export class RollBack extends Component
{           
    render()
    {
        return (  
            this.props.to ?
            <Link to={this.props.to} style={styleLink}><IconsBark size={18} named={"MdOutlineArrowBackIos"} />&nbsp;Voltar</Link>                                                   
            :   <p onClick={this.props.onClick} style={styleLink}><IconsBark size={18} named={"MdOutlineArrowBackIos"} />&nbsp;Voltar</p>
        )
    }
};
