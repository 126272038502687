import styled from "styled-components";

export const Container = styled.div`
  height: 57px;
  background-color: #fff; 
  border-bottom: 1px solid #c8ced3;
  display: flex;
  justify-content: right;
  align-items: center;  
  padding: 15px;
`;

export const ContainerAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContainerText = styled.div`
  line-height: 1.0;
  text-align: right;
  padding-right: 10px;
`

export const ContainerTitle = styled.div`
  font-size:0.955em;
  text-align:right;
`

export const ContainerSubTitle = styled.small`
  color: #73818f !important;
`

export const ItemDropDown = styled.div`
  width: 200px;  
  display: flex;
  justify-content: space-between;
  align-items: center;  
`

export const ItemDropDownText = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`
export const ParagraphContentStyled = styled.p`
  margin-bottom: 0px;
  font-size: 1.2em;
  color: black;
`

export const ParagraphDetailStyled = styled.p`
  font-weight: 700;
  font-size: 1.2em;
  margin-bottom: 0px;
  color: black;
`

export const ModalStyle = styled.div`
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 20px;
  margin-right: ${p => p.isMobile ? "20px" : "30%"};
  margin-left: ${p => p.isMobile ? "20px" : "30%"};
  height: auto;
  background-color: white;
  border-radius: 10px;
`

export const ModalHeaderStyle = styled.div`
  height: 50px; 
  padding: 10px;
`

export const ModalBodyStyle = styled.div`  
  padding: 10px;
  text-align: left;  
`

export const ModalFooterStyle = styled.div`  
  padding: 10px;
  display: flex;
`

export const Cortain = styled.div`
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: black;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 0.5;
`