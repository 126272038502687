import styled from "styled-components";

export const BoxCortina = styled.div`
  display: ${props => props.active ? "block" : "none"};
  left: 0;
  top: 0;
  z-index: 99999999;  
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index:1001;
  opacity:0.3;
`

export const BoxLoading = styled.div`
  display: ${props => props.active ? "block" : "none"};
  background: ${p => `url("${p.server}/assets/img/loading/loading.gif") no-repeat center center`};
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  padding: 16px;
  z-index:1002;
  overflow: auto;
`