import styled from "styled-components";

export const ContainerTotal = styled.div`
  background-image: ${props => "linear-gradient(to right, "+props.secundaria+","+props.primaria+")"};
  height: 100%;
  position: absolute;
  right: 0;
  left: 0;
  padding-right: ${props => props.monitor ? "100px" : "0px"}; 
  padding-left: ${props => props.monitor ? "100px" : "0px"}; 
`
export const ContainerMsgCenter = styled.div`
  display: flex;
  justify-content: center;
  color: white;
`