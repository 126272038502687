import 'rsuite/dist/styles/rsuite-default.css';
import React, { Component } from 'react';
import { Cortain, ModalBodyStyle, ModalFooterStyle, ModalHeaderStyle, ModalStyle, ParagraphContentStyled, ParagraphDetailStyled } from './styles';
import Ferramentas from '../../../classes/ferramentas';

export class ModalBark extends Component {
    isMobile = !Ferramentas.getMonitor();

    render() {
        return (
            <>
                {this.props.isOpen ? <>
                    <Cortain
                        onClick={this.props.toggle}
                    >
                    </Cortain>
                    <ModalStyle isMobile={this.isMobile}
                    >
                        {this.props.children}
                    </ModalStyle>
                </> : null}
            </>
        )
    }
};

export class ModalHeaderBark extends Component {
    render() {
        return (
            <ModalHeaderStyle><ParagraphDetailStyled>{this.props.children}  </ParagraphDetailStyled></ModalHeaderStyle>
        )
    }
};


export class ModalBodyBark extends Component {
    render() {
        return ( 
            <ModalBodyStyle><ParagraphContentStyled>{this.props.children}</ParagraphContentStyled></ModalBodyStyle>
        )
    }
};

export class ModalFooterBark extends Component {
    render() {
        return (            
            <ModalFooterStyle {...this.props}>
                {this.props.children}
            </ModalFooterStyle>
        )
    }
};