import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
  display: flex;
  align-items: center;  
  cursor: pointer;
`

export const BoxText = styled.div`
  margin:0px 0px 0px 10px;
  align-items: center;
  color:${props => props.color};
`
