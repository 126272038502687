import React, { Component } from 'react';
import ColorContext from '../../ColorContext';
import { BoxText, Container } from './styles';
import IconsBark from '../../assets/icons/IconsBark';


export default class RadioSelect extends Component {    
    static contextType = ColorContext;

    constructor(props) {
        super(props);

        this.onSelected = this.onSelected.bind(this);
    }

    onSelected(e,checked){
        if (this.props.onSelected){
            this.props.onSelected({target:{value:this.props.value,checked:checked}});
        }
    }

    render() {
        const {colors} = this.context;
        return (
            <Container onClick={(e)=>this.onSelected(e,!this.props.checked)}>
                {this.props.checked ? <IconsBark named="ImCheckboxChecked" size={16} color={colors.primary} /> : 
                <IconsBark named="ImCheckboxUnchecked" size={16} color={colors.primary}/>}                
                 <BoxText  color={"black"}>{this.props.children}</BoxText>
            </Container>  
        );
    }
}
