import React, {Component} from 'react';
import BoxAlert from '../BoxAlert/BoxAlert';
import FirebaseService from '../../service/FirebaseService';
import { BoxCenter, Container } from './styles';
import Ferramentas from '../../classes/ferramentas';

import aprovadoIMG from '../../assets/img/brand/success.svg';
import reprovadoIMG from '../../assets/img/brand/reprovacao.svg';
import { TitleForm } from '../Base/TitleForm/styles';

export default class Aprovador extends Component {
    constructor(props) {
        super(props);

        this.state = {            
            invalido: {status:false, message:''},          
            resultado:undefined, 
            concluido:false,  
            aprovado:null,
        };
    }

    componentDidMount() {
        FirebaseService.getStatus((result)=>{
            Ferramentas.SetCores(result.primaria,result.secundaria);
            this.setState({primaria:result.primaria,secundaria:result.secundaria});
            const { hash } = window.location;
            const parts = hash.split("/");                
            FirebaseService.Aprovar(parts[parts.length-1],(status, message)=> {
                if (status === "0"){
                    this.setState({resultado:message,aprovado:message.toLowerCase().indexOf("reprovado") === -1});
                } else {
                    this.setState({invalido:{status:true, message:message}});
                }
            });            
          },(r)=>console.log(r)); 
    }


    render() {
        return (
            <>                
                <Container primaria={this.state.primaria} secundaria={this.state.secundaria}>
                    <BoxCenter>
                        {this.state.resultado ? 
                        <>
                            <img height={120} src={this.state.aprovado ? aprovadoIMG : reprovadoIMG} alt="Aprovado-Reprovado" />
                            <div>
                                <TitleForm>{this.state.aprovado ? "Aprovação" : "Reprovação"}</TitleForm>
                                <p>{this.state.resultado}</p>     
                            </div>
                        </>
                        : 
                        <div>
                            <TitleForm>Aguarde...</TitleForm>
                        </div>
    }
                    </BoxCenter>                                  
                                    
                    <div>
                        <BoxAlert style={{marginTop: '20px'}}  open={this.state.invalido.status} color="danger">
                        {this.state.invalido.message}
                        </BoxAlert>
                    </div>           
                </Container>                                              
            </>
        )
    }
}
