import styled from "styled-components";

export const Container = styled.div` 
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;  
`
export const BoxContainerCircle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const BoxCircle = styled.div` 
  height: 10px;
  width: 10px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 20px;
  background-color: ${props => props.selected ? "#F5F5F5" : props.future ? "transparent" : "#286B7D"};
  border: ${props => props.future ? "2px solid #D8D9D9" : "2px solid #286B7D"}; 
`
export const BoxLine = styled.div` 
  height: ${props => props.invisible ? "0px" : "3px"};
  width: 100%;
  background-color: ${props => props.selected || props.future  ? "#D8D9D9" : "#286B7D"};
`

export const BoxStepper = styled.div`
  height: 80px;
  width: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border:0px solid #F5F5F5;
`

export const BoxCircleLine = styled.div`
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 45% 10% 45%;
  justify-content: space-around;
  align-items: center;
`

export const BoxText = styled.div`
  cursor: pointer;
  height: auto;
  width: 100%;
  padding: 10px 20px 0px 20px;
  color: ${props => props.selected ? "white" : props.future ? "#AAC6D2" : "#286B7D"};
  font-weight: 700;

  :hover {
    color: white;
  }
`

export const BoxVoltar = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;  
  margin-left: ${props => props.mobile ? "15px" : "100px"};
`

export const BoxAvancar = styled.div`
  height: 100%;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;  
  margin-right: ${props => props.mobile ? "15px" : "100px"};  
`