import styled from "styled-components";

export const Container  = styled.div`
  position: absolute;
  height: 100%;
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${props => "linear-gradient(to right, "+props.secundaria+","+props.primaria+")"};  
`

export const BoxCenter = styled.div`
  background-color: white;
  padding: 150px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Titulo = styled.p`
  margin-bottom: 2px;
`