import styled from "styled-components";

export const DropdownItemStyled  = styled.button`
  border-radius: ${props => props.header ? "20px 20px 0px 0px" : props.last ? "0px 0px 20px 20px" : "0px"};
  background-color: ${props => props.header ? props.primary : "white"};
  color:${props => props.header ? "white" : props.disabled ? "#73818f" : "#23282c"};
  width: 100%;
  border: 0px solid #c8ced3; 
  border-bottom: 1px solid #c8ced3;
  padding: ${props => props.header ? "5px 20px" : "10px 20px"};
  text-align: ${props => props.header ? "center" : "left"};

  :hover {
    background-color: ${props => props.header ? props.primary : props.disabled ? "white" : "#F0F3F5"};
  }
`

export const Box = styled.div` 
  padding: 0% 0% 0% 0%;
  border:2px solid #F5F5F5;
  border-radius: 20px;
  margin-bottom: 30px;
`

export const BoxTitle = styled.div`
  position: inherit;
  background-color: ${props => props.primary};
  color:white;
  margin: 0px;
  border-radius: 20px 20px 0px 0px;
  height: 30px;
  right: 0;
  left: 0;
  text-align: center;
  padding-top: 4px;
`

export const BoxPontos = styled.div`
  display: inline-block;
  float: right;
  margin-right: 10px;
`
