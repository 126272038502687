import React, { Component } from 'react';
import { BoxAlertStyle } from './styles';

class BoxAlert extends Component {
  render() {
    return ( 
        <div {...this.props}>   
            { this.props.open ? <BoxAlertStyle>{this.props.children}</BoxAlertStyle> : null }
        </div>
    );
  }
}

export default BoxAlert;
