import React, { Component } from 'react';
import ButtonCircle from '../ButtonCircle/ButtonCircle';
import { ModalBark, ModalBodyBark, ModalFooterBark, ModalHeaderBark } from '../Base/ModalBark/ModalBark';
import { Nav } from 'rsuite';

export default class ViewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: props.initialModalState,
            fade: true,
            inButton: props.inButton,
            onYes: props.onYes,
            activeTab: '2',
        };

        this.toggle = this.toggle.bind(this);
        this.toYes = this.toYes.bind(this);
        this.toAbaSelect = this.toAbaSelect.bind(this);
        this.getMontagemConteudo = this.getMontagemConteudo.bind(this);
    }

    toYes() {
        this.setState({
            modal: !this.state.modal,
            fade: !this.state.fade
        });
        this.state.onYes(this.props.chave);
    }

    toggle(e) {
        console.log(e);
        if (this.state.modal) {
            if (e.target.outerText === "OK") {
                this.setState({
                    modal: !this.state.modal,
                    fade: !this.state.fade

                });
            }
        } else {
            this.setState({
                modal: !this.state.modal,
                fade: !this.state.fade

            });
        }
    }

    toAbaSelect(tab) {
        this.setState({
            activeTab: tab,
        });
    }

    getMontagemConteudo() {
        let retorno = [];
        if (this.props.desenhada) {
            retorno.push(
                <Nav appearance="tabs" activeKey={this.state.activeTab} onSelect={(select) => { this.toAbaSelect(select); }}>
                    <Nav.Item eventKey="2">
                        Foto
                    </Nav.Item>
                    <Nav.Item eventKey="1">Desenhada</Nav.Item>
                </Nav>
            );
            retorno.push(this.state.activeTab === "1" ?
                <div>   <img style={{ width: '100%' }} src={this.props.desenhada} alt="imagem nao encontrada" /></div>
                :
                <div>   <img style={{ width: '100%' }} src={this.props.foto} alt="imagem nao encontrada" /></div>
            );
        } else {
            retorno.push(<div key={Math.random()}>   <img style={{ width: '100%' }} src={this.props.foto} alt="imagem nao encontrada" /></div>);
        }
        return retorno;
    }

    render() {
        return (
            <ButtonCircle color={this.props.color} size="sm" onClick={this.toggle}>{this.props.inButton}
                <ModalBark isOpen={this.state.modal} toggle={this.toggle}
                    fade={this.state.fade}
                >
                    <ModalHeaderBark toggle={this.toggle}>{this.props.titulo}</ModalHeaderBark>
                    <ModalBodyBark>
                        {this.getMontagemConteudo()}
                    </ModalBodyBark>
                    <ModalFooterBark style={{display:"flex", justifyContent: "space-between"}}>
                        <h3>{this.props.rodape}</h3>
                        <ButtonCircle color="primary" onClick={this.toggle}>OK</ButtonCircle>
                    </ModalFooterBark>
                </ModalBark>
            </ButtonCircle>
        );
    }
}