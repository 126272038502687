import styled from "styled-components";

export const BoxInputCinza = styled.div`
  height: calc( 100% - 30px );
  background-color: #F5F5F5;
  border-radius: ${props => props.radius === "bottom" ? "0px 0px 20px 20px" : "20px" };
  display: flex;
  width: ${props => props.hasButton ? "auto" : "-webkit-fill-available"};
  border: ${props => props.focus ? "2px solid "+props.primary : "2px solid #F5F5F5"};  
`

export const BoxIconSearchCinza = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;  
`

export const InputStyledCinza = styled.input`
  background-color: ${props => props.model === "cinza" ? "#F5F5F5" : "white"};
  border:0px solid #F5F5F5;
  font-size: 15px;  
  border-radius: ${props => props.radius === "bottom" ? "0px 0px 20px 20px" : "20px" };
  width: -webkit-fill-available;  
  padding-left: ${props => props.model === "cinza" ? "2px" : "8px"};
  padding-right: ${props => props.model === "cinza" ? "2px" : "8px"};  
  width: 100%;

  :focus {
    outline: none !important;    
    background-color: ${props => props.model === "cinza" ? "#F5F5F5" : "white"};
  }
`

export const TextAreaStyledCinza = styled.textarea`
  background-color: ${props => props.model === "cinza" ? "#F5F5F5" : "white"};
  border:2px solid #F5F5F5;
  font-size: 15px;  
  border-radius: ${props => props.radius === "bottom" ? "0px 0px 20px 20px" : "20px" };
  width: -webkit-fill-available;  
  padding-left: ${props => props.model === "cinza" ? "2px" : "8px"};
  padding-right: ${props => props.model === "cinza" ? "2px" : "8px"};  

  :focus {
    outline: none !important;
    //border: ${props => props.model === "cinza" ? "2px solid #F5F5F5" : "2px solid "+props.primary};
    background-color: ${props => props.model === "cinza" ? "#F5F5F5" : "white"};
  }
`