import React, {Component} from 'react';
import ColorContext from '../../ColorContext';
import { Box, BoxCenter, BoxTitle, ValueStyled } from './styles';
export default class QuadroNumerico extends Component
{
    static contextType = ColorContext;
    render()
    {
        const {colors} = this.context;
        return (  
            <Box altura={this.props.altura} window={window.screen.height >= 1080}>
                <BoxTitle primary={colors.primary}> {this.props.titulo}</BoxTitle>
                <BoxCenter>
                <ValueStyled>{this.props.texto}</ValueStyled>
                <h5>{this.props.detalhe}</h5>
                </BoxCenter>
            </Box>
        )
    }
};