import styled from "styled-components";

export const BoxAlertStyle  = styled.div`
  background-color: #F792A3;
    border-radius: 5px;
    padding: 5px;
    color: white;
    margin-top: 10px;
    font-weight: 600;
;
`