import React, {Component} from 'react';
import ColorContext from '../../ColorContext';
import SliderGradiente from '../SliderGradiente/SliderGradiente';
import { Box, BoxCenter, BoxTitle } from './styles';
export default class TrackBar extends Component
{
    static contextType = ColorContext;
    render()
    {
        const {colors} = this.context;
        return (                   
            <Box altura={this.props.altura} window={window.screen.height >= 1080}>
                <BoxTitle primary={colors.primary}> {this.props.title}</BoxTitle>
                <BoxCenter>
                <SliderGradiente
                   Change={this.props.Change}
                   marks={this.props.marks} 
                   step={25}  
                   min={0} 
                   value={this.props.value}
                   max={this.props.max} />
                </BoxCenter>
            </Box>           
        )
    }
};