import React, { Component } from 'react';
import { BarkDropDown, BoxItemMenu, DropdownToggleStyled } from './styles';
import ColorContext from '../../ColorContext';
import { Dropdown } from 'rsuite';
import IconsBark from '../../assets/icons/IconsBark';

export default class ComboBoxCinza extends Component {
    static contextType = ColorContext;

    constructor(props) {
        super(props);

        this.state = {
            dropOpenEscala: false,
        };

        this.click = this.click.bind(this);
        this.defaultValue = this.defaultValue.bind(this);
    }

    click(key, value) {
        if (this.props.onChange) {
            this.props.onChange({ target: { value: key }, preventDefault: () => { } });
        }
        if (this.props.onClicked) {
            this.props.onClicked({ key: key, value: value });
        }
        if (this.props.onSelected) {
            if (this.props?.selecteds?.includes(value)) {
                const newSelecteds = this.props?.selecteds.filter(a => a !== value);
                this.props.onSelected(newSelecteds);
            } else {
                const newSelecteds = this.props?.selecteds.concat(value);
                this.props.onSelected(newSelecteds);
            }
        }
        this.setState({ dropOpenEscala: !this.state.dropOpenEscala });
    }

    defaultValue() {
        let retorno = "";
        if (this.props.mult) {
            retorno = "Todos";
            if (this.props.selecteds && this.props.list) {
                const ls = this.props.list.filter((item) => this.props?.selecteds?.includes(item.value));
                if (ls.length !== this.props.list.length) {
                    retorno = ls.length + " Itens";
                }
            }
        } else {
            retorno = "Selecione:";
            if (this.props.value && this.props.list) {
                const ls = this.props.list.filter((item) => item.key === this.props.value);
                if (ls.length > 0) {
                    retorno = ls[0].value;
                }
            }
        }
        return retorno;
    }

    render() {
        const { colors } = this.context;
        return (
            <BarkDropDown>
                <Dropdown style={{ width: "100%" }} title={this.defaultValue()}
                    renderTitle={children => {
                        return <DropdownToggleStyled header secondary={this.props.secondary ? colors.secondary : null} primary={colors.primary}>{children} <IconsBark style={{ marginLeft: "10px" }} named="BiSolidDownArrow" size={10} /></DropdownToggleStyled>;
                    }}>
                    {
                        this.props.list && this.props.list.filter((item) => item.visible === null || item.visible === undefined).map((item) => {
                            return <Dropdown.Item
                                disabled={item.disabled}
                                key={Math.random()}
                                onClick={(e) => this.click(item.key, item.value)}
                                header={item.key === this.props.list[0].key}
                                last={item.key === this.props.list[this.props.list.length - 1].key}
                            >
                                {this.props.mult ?
                                    <BoxItemMenu>
                                        {<IconsBark color={colors.primary} named={this.props?.selecteds?.includes(item.value) ? "ImCheckboxChecked" : "ImCheckboxUnchecked"} />}
                                        {item.value}
                                    </BoxItemMenu>
                                    : item.render ? item.render
                                        : item.value
                                }
                            </Dropdown.Item>
                        })
                    }
                </Dropdown>
            </BarkDropDown>
        );
    }
}
