//import FirebaseService from '../service/FirebaseService';

export default class Ferramentas {

    static getFormataData(d){
        return Date.parse(d).toString().toUpperCase() !== "NAN" ? ((d.getDate() < 10 ? "0"+d.getDate() : d.getDate() )) + "/" + ((d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : (d.getMonth() + 1)) + "/" + d.getFullYear() :null;
    };

    static getEtapas(){
        return [
            //{ label: 'Voltar ao Cliente', tipos: [], diferenciado: true, icone:"icon-arrow-left" },
            { label: 'Grau de Alopécia', tipos:[ "Superior","Costas","Lateral esquerdo","Lateral direita" ] }, 
            { label: 'Densidade Folicular', tipos: ["Area acometida","Area nao acometida"] }, 
            { label: 'Analise do Couro Cabeludo', tipos: ['Paciente'] },
            { label: 'Espessura dos Fios', tipos: ["Area acometida","Area nao acometida"] },
            { label: 'Conclusão', tipos: [] },
        ];
    };

    static getPaletteColors(){
        return [
            '160, 221, 255',
            '117, 142, 205',
            '193, 206, 254',
            '113, 137, 255',
            '98, 76, 171',
            '141, 90, 151',
            '144, 127, 159',
            '164, 165, 174',
            '176, 199, 189',
            '184, 235, 208'
        ]
    };

    static cpfMask = value => {
        let retorno = value;
        if (retorno){
            retorno = retorno
                .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
                .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
        }
        return retorno;
    }

    static dataMask = value => {
        let retorno = value;
        if (retorno){
            let tmp = retorno.split('-');
            retorno = tmp[2]+'/'+tmp[1];
            if (tmp[0] !== '0000'){
                retorno += '/'+tmp[0];
            }
        }
        return retorno;
    }

    static Slug = () => {
        const hashes = window.location.hash;
        return hashes.split('/')[1];
    }

    static FirstLetterUpper = (_text) => {
        if (_text){
            let parts = _text.trim().split(' ');
            for (let i = 0; i < parts.length; i++) {
                parts[i] = parts[i][0].toUpperCase() + parts[i].substr(1);
            }
            return parts.join(' ');
        } else {
            return '';
        }
    }

    static SetCores = (primaria, secundaria) => {
        sessionStorage.setItem('p',primaria);
        sessionStorage.setItem('s',secundaria);
    }

    static CorPrimaria = () => {
        return sessionStorage.getItem('p');
    }

    static CorSecundaria = () => {
        return sessionStorage.getItem('s');
    }
    
    static ReplaceAll = function (_texto, _de, _para)
    {
       return _texto.split(_de).join(_para);
    };

    static getMonitor = () => window.screen.width > 500;    
}