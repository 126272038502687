import React, { Component } from 'react';
import QuadroFotos from '../../QuadroFotos/QuadroFotos';
import PanelEscala from '../../PanelEscala/PanelEscala';
import QuadroEscala from '../../QuadroEscala/QuadroEscala';
import CardText from '../../CardText/CardText';
import CardBodyBark from '../../Base/CardBodyBark/CardBodyBark';

export default class Etapa1 extends Component {
    render() {
        return (
            <div style={{ height: "100%", display: "grid", gridTemplateColumns: "70% 30%" }}>
                <div>
                    <QuadroFotos
                        inactive={this.props.inactive}
                        onExcluir={this.props.toExcluir}       
                        colunas={2}
                        passos={this.props.etapas}
                        data={this.props.analise_quadro} />
                </div>
                <div style={{ display: "grid", gridTemplateRows: "auto 1fr" }}>
                    <div style={{ paddingLeft: '1%', marginBottom: "20px" }}>
                        <PanelEscala
                            titulo={"Escala " + this.props.getNomeEscala()}
                            escalas_lista={this.props.escalas_lista}
                            onClick={this.props.EscalaClick}
                            atual={this.props.escala}
                        >
                            <CardBodyBark style={{ padding: '0%' }}>
                                <QuadroEscala modelo={this.props.escala}
                                    onClick={this.props.selectOpcaoEscala}
                                    selecao={this.props.escala_selecao} />
                            </CardBodyBark>
                        </PanelEscala>
                    </div>
                    <div style={{ paddingLeft: '1%' }}>
                        <CardText placeholder={'Digite aqui...'}
                            height={'100%'}
                            value={this.props.analise_obs}
                            onChange={this.props.toChangeTextObsv1}
                            titulo={'Parecer Médico'} />
                    </div>
                </div>
            </div>
        )
    }
};