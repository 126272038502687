import styled from "styled-components";

export const Container = styled.div`
  background-color: white;
  display: flex;
  align-items: center;  
  cursor: pointer;  
`

export const BoxText = styled.div`
  margin:0px 0px 0px 10px;
  align-items: center;
  color:${props => props.color};
`

export const BoxSelect = styled.div`
  background-color: ${props => props.selected ? props.primary : "white"};
  border-radius: 15px;
  border: ${props => "2px solid "+props.primary};
  height: 15px;
  width: 15px;
`
