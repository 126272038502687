import styled from "styled-components";
import RowBark from "../Base/RowBark/RowBark";

export const RowStyled  = styled(RowBark)`
  margin-top: 20px;
  width: 98%;  
`

export const Box = styled.div` 
  padding: 0% 0% 0% 0%;
  border:2px solid #F5F5F5;
  border-radius: 20px;
`

export const BoxCenterImage = styled.div`
  border-radius: 20px;
  text-align:left;
  height:calc(100% - 30px);
  display:flex; 
  align-items:center;
  justify-content:center;
  background-color:rgb(255, 255, 255);

  :hover .middle {
    opacity: 1;
  }

  :hover > img {
    opacity: 0.1;
  }
`

export const BoxTitle = styled.p`
  position: inherit;
  background-color: #45A8CA;
  color:white;
  margin: 0px;
  border-radius: 20px 20px 0px 0px;
  height: 30px;
  right: 0;
  left: 0;
  text-align: center;
  padding-top: 4px;
`

export const ImageStyled = styled.img`
  opacity: 1;
  height: 100%;
  transition: .5s ease;
  backface-visibility: hidden;

  //width: 100%;
  //border-radius: 0px 0px 20px 20px;
`
export const ContainerCard = styled.div`  
  text-align:left;
  height:100%;
  display:flex; 
  align-items:center;
  justify-content:center;  
`